import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import ImgIcon from '~/components/common/img-icon';
import CreateNodeDialog from '~/components/common/create-node-dialog';
import EditClusterDialog from '~/components/common/edit-cluster-dialog';
import DeleteClusterDialog from '~/components/common/delete-cluster-dialog';
import CreateBlockExplorerDialog from '~/components/common/create-block-explorer-dialog';
import OptionButton from '~/components/common/option-button';
import SortIcon from '~/components/common/sort-icon';
import TableHeadCustom from '~/components/common/table-head';
import TableBodyCustom from '~/components/common/table-body';
import TableCellHeadCustom from '~/components/common/table-cell-head';
import TableCellBodyCustom from '~/components/common/table-cell-body';
import TableRowHeadCustom from '~/components/common/table-row-head';
import TableRowBodyCustom from '~/components/common/table-row-body';
import NodeStatusIcon from '~/components/common/node-status-icon';
import ChangeClusterTypeDialog from './change-cluster-type-dialog';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  pattensBlueColor,
  lightSlateGreyColor,
  whiteSmokeColor,
  denimColor,
  matterhornColor,
  snowColor,
} from '~/styles/themes/common-styles/color';
import {
  displayNodeStatus,
  displayNodeStatusDetail,
  displayBlockExplorerStatus,
  displayBlockExplorerStatusDetail,
  renderClusterRegionLabel,
} from '~/utilities/render-utils';
import { Order, getComparator, stableSort, HeadCell } from '~/utilities/sort-utils';
// Route
import { Link } from 'react-router-dom';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
import { Account, AccountRoleType, NetworkRoleType } from '~/types/account-types';
import { INetwork, ICluster, INodeDisplay, INetworkProvider } from '~/types/network-types';
import { isAccountOwnCluster, isConsortiumAdmin } from '~/utilities/utils';
import { MASK_ASTERISK } from '~/constants/consts';

interface IStateProps {
  selectedAccount?: Account;
  selectedAccountRole?: AccountRoleType;
  selectedNetworkRole?: NetworkRoleType;
  providers: INetworkProvider[];
}

interface IDispProps {}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  network: INetwork;
  cluster: ICluster;
}

interface IState {
  clusterOptionAnchorEl?: any;
  openCreateNodeDialog: boolean;
  openCreateBlockExplorerDialog: boolean;
  openEditClusterDialog: boolean;
  openDeleteClusterDialog: boolean;
  order: Order;
  orderBy: SortableHeadCellId;
  openChangeClusterTypeDialog: boolean;
}

class OverviewTab extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      openCreateNodeDialog: false,
      openEditClusterDialog: false,
      openDeleteClusterDialog: false,
      openCreateBlockExplorerDialog: false,
      openChangeClusterTypeDialog: false,
      order: 'ASC',
      orderBy: 'nodeName',
    };
  }

  public render() {
    const {
      classes,
      cluster,
      network,
      t,
      selectedAccountRole,
      selectedNetworkRole,
      providers,
      selectedAccount,
    } = this.props;
    const updatableCluster =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'operator';
    const deletableCluster =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'operator';
    const creatableNode =
      selectedAccountRole === 'owner' ||
      selectedAccountRole === 'admin' ||
      selectedNetworkRole === 'owner' ||
      selectedNetworkRole === 'admin' ||
      selectedNetworkRole === 'operator';
    const {
      clusterOptionAnchorEl,
      openCreateNodeDialog,
      openCreateBlockExplorerDialog,
      openEditClusterDialog,
      openDeleteClusterDialog,
      openChangeClusterTypeDialog,
      order,
      orderBy,
    } = this.state;
    const items = this.getItems();

    const showSensitiveData =
      isConsortiumAdmin(selectedAccount, network) || isAccountOwnCluster(selectedAccount, cluster);

    return (
      <div className={classes.root}>
        <ChangeClusterTypeDialog
          open={openChangeClusterTypeDialog}
          onClose={this.onCloseChangeClusterTypeDialog}
          network={network}
          cluster={cluster}
        />
        <CreateNodeDialog
          networkUuid={network.networkUuid}
          cluster={cluster}
          open={openCreateNodeDialog}
          onClose={this.onCloseCreateNodeDialog}
          network={network}
        />
        <CreateBlockExplorerDialog
          open={openCreateBlockExplorerDialog}
          onClose={this.onCloseCreateBlockExplorerDialog}
          cluster={cluster}
          network={network}
        />
        <EditClusterDialog
          open={openEditClusterDialog}
          onClose={this.onCloseEditClusterDialog}
          cluster={cluster}
          network={network}
        />
        <DeleteClusterDialog
          open={openDeleteClusterDialog}
          onClose={this.onCloseDeleteClusterDialog}
          network={network}
          cluster={cluster}
        />
        <Popover
          open={Boolean(clusterOptionAnchorEl)}
          anchorEl={clusterOptionAnchorEl}
          onClose={this.onCloseClusterOptionPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={1}
          classes={{ paper: classes.nodeOptionPopoverPaper }}
        >
          <List className={classes.listOption}>
            <ListItem
              data-testid="create-node-button"
              button
              className={classes.listOptionItem}
              onClick={this.onOpenCreateNodeDialog}
              disabled={!creatableNode}
            >
              {this.props.t('create_node')}
            </ListItem>
            {!cluster.explorer && (
              <ListItem
                data-testid="create-explorer-button"
                button
                className={classes.listOptionItem}
                onClick={this.onOpenCreateBlockExplorerDialog}
                disabled={!creatableNode}
              >
                {this.props.t('create_block_explorer')}
              </ListItem>
            )}
            <ListItem
              id="change-cluster-type-button"
              button
              className={classes.listOptionItem}
              onClick={this.onOpenChangeClusterTypeDialog}
              disabled={!updatableCluster}
              data-testid="create-node-item"
            >
              {this.props.t('change_cluster_type')}
            </ListItem>
            <ListItem
              data-testid="edit-cluster-button"
              id="network-cluster-button-edit"
              button
              className={classes.listOptionItem}
              onClick={this.onOpenEditClusterDialog}
              disabled={!updatableCluster}
            >
              <ImgIcon className={classes.editIcon} imgUrl="/images/icons/edit-ico.png" />
              <span>{t('edit')}</span>
            </ListItem>
            <div className={classes.horizontalSeparate}></div>
            <ListItem
              data-testid="delete-cluster-button"
              button
              className={classes.listOptionItem}
              onClick={this.onOpenDeleteClusterDialog}
              disabled={!deletableCluster}
            >
              <ImgIcon className={classes.deleteIcon} imgUrl="/images/icons/delete_ico.png" />
              {this.props.t('delete_cluster')}
            </ListItem>
          </List>
        </Popover>

        <div className={classes.borderBlock}>
          <div className={classes.titleArea}>
            <span className={classes.clusterTitle}>{this.props.t('cluster_information')}</span>
            <div className={classes.handleBtnArea}>
              {cluster.explorer && showSensitiveData && (
                <>
                  <Button
                    data-testid="block-explorer-button"
                    id="network-cluster-button-metrics"
                    className={classes.handelBtn}
                    onClick={() => this.onOpenBlockExplorerUrl(cluster)}
                  >
                    {this.props.t('block_explorer')}
                  </Button>
                  <div className={classes.verticalSeparate}></div>
                </>
              )}

              {cluster.accountUuid === selectedAccount?.accountUuid && (
                <OptionButton
                  data-testid="option-button"
                  id="network-cluster-button-option"
                  onClick={this.onOpenClusterOptionPopover}
                />
              )}
            </div>
          </div>

          <div id="cluster-information" className={classes.contentArea}>
            <div className={classes.clusterItemInfo}>
              <div className={classes.subTitle}>{this.props.t('cluster_name')}</div>
              <div className={classes.detailValue}>{cluster.clusterName}</div>
            </div>
            <div className={classes.clusterItemInfo}>
              <div className={classes.subTitle}>{this.props.t('cluster_id')}</div>
              <div className={classes.detailValue}>{cluster.clusterUuid}</div>
            </div>
            <div className={classes.clusterItemInfo}>
              <div className={classes.subTitle}>{this.props.t('signer_node_count')}</div>
              <div className={classes.detailValue}>
                {cluster.nodes.filter((n) => n.nodeInfo.signer).length}
              </div>
            </div>
            <div className={classes.clusterItemInfo}>
              <div className={classes.subTitle}>{this.props.t('transaction_node_count')}</div>
              <div className={classes.detailValue}>
                {cluster.nodes.filter((n) => !n.nodeInfo.signer).length}
              </div>
            </div>
            <div className={classes.clusterItemInfo}>
              <div className={classes.subTitle}>{this.props.t('cluster_type')}</div>
              <div className={classes.detailValue}>
                {showSensitiveData ? this.props.t(cluster.clusterType || '') : MASK_ASTERISK}
              </div>
            </div>
            <div className={classes.clusterItemInfo}>
              <div className={classes.subTitle}>{this.props.t('region')}</div>
              <div className={classes.detailValue}>
                {showSensitiveData
                  ? renderClusterRegionLabel(providers, cluster) || 'fetching ...'
                  : MASK_ASTERISK}
              </div>
            </div>
            <div className={classes.clusterItemInfo}>
              <div className={classes.subTitle}>{this.props.t('endpoint')}</div>
              <div className={classes.detailValue}>
                {showSensitiveData
                  ? this.showEndpointUrl(cluster) || 'fetching ...'
                  : MASK_ASTERISK}
              </div>
            </div>
          </div>
        </div>

        <div className={classes.tableArea}>
          <div className={classes.nodeTitle}>{this.props.t('nodes')}</div>
          <Table id="cluster-nodes-list">
            <colgroup>
              <col width="auto" />
              <col width="200px" />
              <col width="200px" />
            </colgroup>
            <TableHeadCustom>
              <TableRowHeadCustom>
                {headCells.map((headCell) => (
                  <TableCellHeadCustom
                    key={headCell.id}
                    data-testid="sort-button"
                    onClick={this.onTableCellHeadClick(headCell.id)}
                    style={{ cursor: headCell.sortable ? 'pointer' : 'unset' }}
                    classes={{ content: classes.tableCellHeadContent }}
                  >
                    <span>{t(headCell.label)}</span>
                    {headCell.sortable && (
                      <SortIcon order={orderBy === headCell.id ? order : undefined} />
                    )}
                  </TableCellHeadCustom>
                ))}
              </TableRowHeadCustom>
            </TableHeadCustom>
            <TableBodyCustom>
              {items.map((item) => (
                <TableRowBodyCustom key={item.nodeUuid} className={classes.tableRowBodyCustom}>
                  <TableCellBodyCustom>
                    <Link to={item.link}>
                      <div className={classes.nodeLinkText}>
                        <NodeStatusIcon
                          className={classes.nodeStatusIcon}
                          status={item.nodeStatus}
                        />
                        {item.nodeName}
                      </div>
                    </Link>
                  </TableCellBodyCustom>
                  <TableCellBodyCustom>
                    <span className={classes.tableCellStatusText}>{item.nodeState}</span>
                  </TableCellBodyCustom>
                  <TableCellBodyCustom>{item.nodeType}</TableCellBodyCustom>
                </TableRowBodyCustom>
              ))}
              {cluster.explorer && (
                <TableRowBodyCustom className={classes.tableRowBodyCustom}>
                  <TableCellBodyCustom>
                    <Link
                      data-testid="navigate-to-explorer"
                      to={`/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/explorer/overview`}
                    >
                      <div className={classes.nodeLinkText}>
                        <NodeStatusIcon
                          className={classes.nodeStatusIcon}
                          status={displayBlockExplorerStatus(cluster)}
                        />
                        Block Explorer
                      </div>
                    </Link>
                  </TableCellBodyCustom>
                  <TableCellBodyCustom>
                    <span className={classes.tableCellStatusText}>
                      {displayBlockExplorerStatusDetail(cluster, t, true)}
                    </span>
                  </TableCellBodyCustom>
                  <TableCellBodyCustom>Blockscout</TableCellBodyCustom>
                </TableRowBodyCustom>
              )}
            </TableBodyCustom>
          </Table>
        </div>
      </div>
    );
  }

  private getItems = (): Array<Item> => {
    const { network, cluster, t } = this.props;
    const { order, orderBy } = this.state;
    const items: Array<Item> = cluster.nodes.map((node) => ({
      nodeUuid: node.nodeUuid,
      nodeName: node.nodeName,
      nodeStatus: displayNodeStatus(node),
      nodeState: displayNodeStatusDetail(node, t, true),
      nodeType: node.nodeInfo.signer ? t('validator_node') : t('relay_node'),
      link: `/network/${network.networkUuid}/cluster/${cluster.clusterUuid}/node/${node.nodeUuid}/overview`,
    }));

    return stableSort<Item>(items, getComparator<SortableHeadCellId>(order, orderBy));
  };

  private onTableCellHeadClick = (id: SortableHeadCellId) => () => {
    const { order, orderBy } = this.state;
    this.setState({
      orderBy: id,
      order: orderBy === id && order === 'ASC' ? 'DESC' : 'ASC',
    });
  };

  private showEndpointUrl = (cluster: ICluster) => {
    let result: string = '';

    switch (cluster.status) {
      case 'alive':
        result = cluster.endpointUrl!;
        break;
      case 'pending':
        // still fetching ...
        break;
      default:
        result = 'N/A';
        break;
    }
    return result;
  };

  private onOpenBlockExplorerUrl = (cluster: ICluster) => {
    if (!cluster.explorer) {
      return;
    }
    const { status } = cluster.explorer.serverInfo;
    const explorer = cluster.listeners?.find((val) => val.type === 'BLOCK_EXPLORER');
    if (!explorer) {
      window.alert(this.props.t('block_explorer_not_specified'));
    } else {
      if (status === 'alive') {
        window.open(
          `${cluster.endpointUrl}${explorer.port === 443 ? '' : `:${explorer.port}`}/`,
          '_blank',
        );
      } else {
        const msg = status === 'pending' ? 'not launched yet' : 'not available';
        window.alert(`Block Explorer ${msg}.`);
      }
    }
  };

  private onOpenClusterOptionPopover = (e) => {
    this.setState({
      clusterOptionAnchorEl: e.currentTarget,
    });
  };

  private onCloseClusterOptionPopover = () => {
    this.setState({
      clusterOptionAnchorEl: null,
    });
  };

  private onOpenCreateNodeDialog = () => {
    this.setState({
      openCreateNodeDialog: true,
    });
  };

  private onCloseCreateNodeDialog = () => {
    this.setState({
      openCreateNodeDialog: false,
    });
  };

  private onOpenCreateBlockExplorerDialog = () => {
    this.setState({
      openCreateBlockExplorerDialog: true,
    });
  };

  private onCloseCreateBlockExplorerDialog = () => {
    this.setState({
      openCreateBlockExplorerDialog: false,
    });
  };

  private onOpenEditClusterDialog = () => {
    this.setState({
      openEditClusterDialog: true,
    });
  };

  private onCloseEditClusterDialog = () => {
    this.setState({
      openEditClusterDialog: false,
    });
  };

  private onOpenDeleteClusterDialog = () => {
    this.onCloseClusterOptionPopover();
    this.setState({
      openDeleteClusterDialog: true,
    });
  };

  private onCloseDeleteClusterDialog = () => {
    this.setState({
      openDeleteClusterDialog: false,
    });
  };

  private onOpenChangeClusterTypeDialog = () => {
    this.setState({
      openChangeClusterTypeDialog: true,
      clusterOptionAnchorEl: null,
    });
  };

  private onCloseChangeClusterTypeDialog = () => {
    this.setState({
      openChangeClusterTypeDialog: false,
    });
  };
}

const styles = createStyles({
  root: {
    paddingBottom: 50,
  },
  nodeTitle: {
    ...defaultFontMedium,
    fontSize: 16,
    color: lightSlateGreyColor,
    marginBottom: 21,
  },
  nodeOptionPopoverPaper: {},
  clusterTitle: {
    ...defaultFont,
    fontSize: 15,
    color: lightSlateGreyColor,
  },
  titleArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 36,
    backgroundColor: snowColor,
    paddingLeft: 20,
    paddingRight: 10,
  },
  contentArea: {
    paddingTop: 20,
    paddingBottom: 30,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridRowGap: '20px',
    wordBreak: 'break-all',
  },
  clusterItemInfo: {
    paddingLeft: 20,
  },
  separateBorder: {
    borderRight: `1px solid ${pattensBlueColor}`,
  },
  borderBlock: {
    marginTop: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    borderColor: pattensBlueColor,
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
    position: 'relative',
  },
  subTitle: {
    ...defaultFontBold,
    fontSize: 12,
  },
  detailValue: {
    ...defaultFont,
    fontSize: 15,
    color: lightSlateGreyColor,
  },
  // Node Type
  nodeType: {
    ...defaultFontMedium,
    display: 'flex',
    alignItems: 'center',
    height: 24,
    borderRadius: 14,
    paddingLeft: 15,
    paddingRight: 15,
    fontSize: 12,
    color: matterhornColor,
  },
  backgroundRomanColor: {
    // backgroundColor: romanColor,
    backgroundColor: 'rgb(227, 90, 90, 0.2)',
  },
  backgroundSummerSkyColor: {
    // backgroundColor: summerSkyColor,
    backgroundColor: 'rgb(64, 194, 230, 0.2)',
  },
  backgroundCreamCanColor: {
    // backgroundColor: creamCanColor,
    backgroundColor: 'rgb(240, 193, 80, 0.2)',
  },
  nodeItemLeft: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    ...defaultFont,
    fontSize: 13,
    color: denimColor,
    '& span': {
      marginLeft: 10,
    },
  },
  nodeItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 40,
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: whiteSmokeColor,
    border: `1px solid ${pattensBlueColor}`,
    borderRadius: 4,
    paddingLeft: 10,
    paddingRight: 10,
  },
  // Create Node Button
  createNodeBtn: {
    width: '100%',
    height: 40,
    // '& span': {
    //   position: 'relative',
    //   justifyContent: 'center',
    // },
  },
  addNodeIcon: {
    position: 'absolute',
    left: 0,
  },
  createNodeBtnContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  recentEvent: {
    marginTop: 30,
    '& th:first-child': {
      paddingLeft: 10,
    },
  },
  recentEventTitle: {
    ...defaultFontBold,
    fontSize: 16,
    color: lightSlateGreyColor,
    marginBottom: 21,
  },
  // Handle Button
  handleBtnArea: {
    display: 'flex',
    alignItems: 'center',
  },
  handelBtn: {
    ...defaultFontMedium,
    fontSize: 12,
    color: lightSlateGreyColor,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  verticalSeparate: {
    width: 1,
    height: 20,
    backgroundColor: pattensBlueColor,
    marginRight: 5,
    marginLeft: 5,
  },
  // Node option popover
  listOption: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listOptionItem: {
    height: 46,
    width: 210,
    ...defaultFont,
    fontSize: 16,
  },
  editIcon: {
    width: 14,
    height: 14,
    marginRight: 7,
  },
  deleteIcon: {
    marginRight: 8,
  },
  horizontalSeparate: {
    height: 1,
    width: '100%',
    backgroundColor: pattensBlueColor,
  },
  nodeTableHead: {
    cursor: 'pointer',
  },
  sortIconInvisibility: {
    display: 'none',
  },
  tableArea: {
    marginTop: 36,
  },
  tableCellHeadContent: {
    paddingRight: 20,
  },
  nodeStatusIcon: {
    marginRight: 10,
    verticalAlign: 'middle',
  },
  nodeLinkText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: denimColor,
  },
  tableCellStatusText: {
    verticalAlign: 'middle',
    color: lightSlateGreyColor,
  },
  recentEventDate: {
    ...defaultFont,
    color: lightSlateGreyColor,
    paddingLeft: 10,
  },
  tableRowBodyCustom: {
    height: '48px',
    '& .MuiTableCell-root': {
      letterSpacing: 'normal',
      padding: '4px 30px 4px 10px',
    },
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  selectedAccount: store.appState.accountSeleted,
  selectedAccountRole: store.appState.accountSeleted && store.appState.accountSeleted.role,
  selectedNetworkRole: store.appState.networkSelected && store.appState.networkSelected.role,
  providers: store.appState.providers || [],
});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OverviewTab)),
);

type Item = {
  nodeUuid: string;
  nodeName: string;
  nodeStatus: INodeDisplay;
  nodeState: string;
  nodeType: string;
  link: string;
};
type SortableHeadCellId = keyof Omit<Item, 'nodeUuid' | 'nodeStatus' | 'link'>;
const headCells: Array<HeadCell<SortableHeadCellId, never>> = [
  { id: 'nodeName', sortable: true, label: 'name' },
  { id: 'nodeState', sortable: true, label: 'states' },
  { id: 'nodeType', sortable: true, label: 'node_type' },
];
