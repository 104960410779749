import GApiClient from '~/gapi/gapi-client';
import {
  listProvidersQuery,
  QueryListProvidersArgs,
  LIST_PROVIDERS_RESULT_TYPE,
  listNetworksQuery,
  QueryListNetworksArgs,
  LIST_NETWORKS_RESULT_TYPE,
  createNetworkMutation,
  MutationCreateNetworkArgs,
  CREATE_NETWORK_RESULT_TYPE,
  destroyNetworkMutation,
  MutationDestroyNetworkArgs,
  DESTROY_NETWORK_RESULT_TYPE,
  MutationUpdateNetworkArgs,
  UPDATE_NETWORK_RESULT_TYPE,
  updateNetworkMutation,
  MutationCreateClusterArgs,
  createClusterMutation,
  CREATE_CLUSTER_RESULT_TYPE,
  MutationUpdateClusterArgs,
  updateClusterMutation,
  UPDATE_CLUSTER_RESULT_TYPE,
  MutationDeleteClusterArgs,
  deleteClusterMutation,
  DELETE_CLUSTER_RESULT_TYPE,
  MutationCreateNodeArgs,
  createNodeMutation,
  CREATE_NODE_RESULT_TYPE,
  MutationUpdateNodeArgs,
  updateNodeMutation,
  UPDATE_NODE_RESULT_TYPE,
  MutationDeleteNodeArgs,
  deleteNodeMutation,
  DELETE_NODE_RESULT_TYPE,
  MutationControlNodeArgs,
  MutationControlBlockExplorerArgs,
  controlNodeMutation,
  CONTROL_NODE_RESULT_TYPE,
  MutationGrantNetworkRoleArgs,
  GRANT_NETWORK_ROLE_RESULT_TYPE,
  grantNetworkRoleMutation,
  MutationRevokeNetworkRoleArgs,
  REVOKE_NETWORK_ROLE_RESULT_TYPE,
  revokeNetworkRoleMutation,
  listNetworkAccessesQuery,
  QueryListNetworkAccessesArgs,
  LIST_NETWORK_ACCESSES_RESULT_TYPE,
  findClustersQuery,
  QueryFindClustersArgs,
  FIND_CLUSTERS_RESULT_TYPE,
  findNodesQuery,
  QueryFindNodesArgs,
  FIND_NODES_RESULT_TYPE,
  getNetworkQuery,
  GET_NETWORK_RESULT_TYPE,
  QueryGetNetworkArgs,
  getNetworkRoleQuery,
  GET_NETWORK_ROLE_RESULT_TYPE,
  QueryGetNetworkRoleArgs,
  listEndpointRestrictionsQuery,
  QueryListEndpointRestrictionsArgs,
  LIST_ENDPOINT_RESTRICTIONS_RESULT_TYPE,
  listExplorerRestrictionsQuery,
  QueryListExplorerRestrictionsArgs,
  LIST_EXPLORER_RESTRICTIONS_RESULT_TYPE,
  listNodeRestrictionsQuery,
  QueryListNodeRestrictionsArgs,
  LIST_NODE_RESTRICTIONS_RESULT_TYPE,
  setEndpointRestrictionMutation,
  MutationSetEndpointRestrictionArgs,
  SET_ENDPOINT_RESTRICTION_RESULT_TYPE,
  setExplorerRestrictionMutation,
  MutationSetExplorerRestrictionArgs,
  SET_EXPLORER_RESTRICTION_RESULT_TYPE,
  setNodeRestrictionMutation,
  MutationSetNodeRestrictionArgs,
  SET_NODE_RESTRICTION_RESULT_TYPE,
  expandNodeVolumeMutation,
  MutationExpandNodeVolumeArgs,
  EXPAND_NODE_VOLUME_RESULT_TYPE,
  getExplorerMetricsQuery,
  QueryGetExplorerMetricsArgs,
  GET_EXPLORER_METRICS_RESULT_TYPE,
  getNodeMetricsQuery,
  QueryGetNodeMetricsArgs,
  GET_NODE_METRICS_RESULT_TYPE,
  getExplorerLogQuery,
  QueryGetExplorerLogArgs,
  GET_EXPLORER_LOG_RESULT_TYPE,
  getNodeLogQuery,
  QueryGetNodeLogArgs,
  GET_NODE_LOG_RESULT_TYPE,
  sendAllEtherFromNodeMutation,
  MutationSendAllEtherFromNodeArgs,
  SEND_ALL_ETHER_FROM_NODE_RESULT_TYPE,
  estimateTransferGasQuery,
  QueryEstimateTransferGasArgs,
  ESTIMATE_TRANSFER_GAS_RESULT_TYPE,
  CONTROL_BLOCK_EXPLORER_RESULT_TYPE,
  controlBlockExplorerMutation,
  MutationExpandBlockExplorerVolumeArgs,
  EXPAND_BLOCK_EXPLORER_VOLUME_RESULT_TYPE,
  expandBlockExplorerVolumeMutation,
  checkClusterRemovalStatusQuery,
  QueryCheckClusterRemovalStatusArgs,
  CLUSTER_REMOVAL_STATUS_RESULT_TYPE,
  checkNodeRemovalStatusQuery,
  QueryCheckNodeRemovalStatusArgs,
  NODE_REMOVAL_STATUS_RESULT_TYPE,
  getTxpoolStatusQuery,
  QueryGetTxpoolStatusArgs,
  GET_TXPOOL_STATUS_RESULT_TYPE,
  MutationAddCustomDomainArgs,
  addCustomDomainMutation,
  ADD_CUSTOM_DOMAIN_RESULT_TYPE,
  MutationRemoveCustomDomainArgs,
  removeCustomDomainMutation,
  REMOVE_CUSTOM_DOMAIN_RESULT_TYPE,
  MutationCheckCustomDomainStatusArgs,
  checkCustomDomainStatusMutation,
  CHECK_CUSTOM_DOMAIN_STATUS_RESULT_TYPE,
  addBlockExplorerMutation,
  MutationToCreateBlockExplorerArgs,
  ADD_BLOCK_EXPLORER_RESULT_TYPE,
  deleteBlockExplorer,
  REMOVE_BLOCK_EXPLORER_RESULT_TYPE,
  MutationDeleteBlockExplorerArgs,
} from './query/network';
import * as QueryNetwork from './query/network';
import * as QueryAccount from './query/account';
import * as QueryGeolocation from './query/geolocation';
import {
  listAlertsQuery,
  QueryListAlertsArgs,
  LIST_ALERTS_RESULT_TYPE,
  MutationSetAlertReadArgs,
  SET_ALERT_READ_RESULT_TYPE,
  MutationSetAllAlertsReadArgs,
  SET_ALL_ALERTS_READ_RESULT_TYPE,
  setAlertReadMutation,
  setAllAlertsReadMutation,
} from './query/alert';
import * as QueryProposal from './query/proposal';
import * as QueryInvitation from './query/invitation';
import * as QueryPayment from './query/payment';
import { getHostFromClient } from '~/utilities/utils';
import {
  MutationUpdateNodeVersionArgs,
  MutationUpdateBlockExplorerArgs,
  MutationUpdateNodeExplorerVersionArgs,
  MutationUpdateNodeInstanceTypeArgs,
  MutationUpdateBlockExplorerInstanceTypeArgs,
  QueryListInsufficientBlockExpInstanceLicensesArgs,
  MutationVoteHardForkProposalArgs,
  QueryListHardForkProposalsArgs,
  MutationCreateHardForkProposalArgs,
  MutationApplyHardForkToBlockExplorerArgs,
  MutationApplyHardForkToNodeArgs,
  MutationRetryApplyHardForkToNetworkArgs,
  MutationUpdateBlockGasLimitArgs,
  QueryGetGenesisJsonArgs,
  QueryGetGenesisJsonInExplorerArgs,
  MutationDiscardProposalArgs,
  MutationSendProposalArgs,
  QueryListProposalHistoryArgs,
  MutationUpdateExternalClefArgs,
  MutationUpdateBlockExplorerVersionArgs,
  MutationSetEndpointListenerArgs,
  MutationUpdateRuleOfInternalClefArgs,
  MutationMigrateToInternalClefNodeArgs,
  MutationRefreshExternalClefConnectionArgs,
  QueryListInsufficientStaticIpAddressLicensesArgs,
  MutationAssociateStaticIpAddressArgs,
  MutationDisassociateStaticIpAddressArgs,
  MutationMigrateBootnodeArgs,
  MutationUpdateClusterTypeArgs,
  QueryListInsufficientClusterTypeLicensesArgs,
} from '~/gapi/gtypes';
import { env } from '~/env';

export default class MemberGApiClient extends GApiClient {
  constructor({ token, baseUrl }: { token?: string; baseUrl?: string }) {
    const backendApiUrl = env.REACT_APP_BACKEND_API_URL;
    super({
      baseUrl: baseUrl || backendApiUrl || getHostFromClient(),
      endPoint: '/gapi/member',
      credentials: backendApiUrl ? 'include' : 'same-origin',
      token: token,
    });
  }

  public async getProfile() {
    return this.doQuery<QueryAccount.GET_PROFILE_RESULT_TYPE, void>(
      this.endPoint,
      QueryAccount.getProfileQuery,
    );
  }

  public async selectNetwork(args: QueryAccount.MutationSelectNetworkArgs) {
    return this.doMutate<
      QueryAccount.UPDATE_SELECT_NETWORK_RESULT_TYPE,
      QueryAccount.MutationSelectNetworkArgs
    >(this.endPoint, QueryAccount.selectNetworkMutation, args);
  }

  public async updateProfile(args: QueryAccount.MutationUpdateProfileArgs) {
    return this.doMutate<
      QueryAccount.UPDATE_PROFILE_RESULT_TYPE,
      QueryAccount.MutationUpdateProfileArgs
    >(this.endPoint, QueryAccount.updateProfileMutation, args);
  }

  public async getAccount(args: QueryAccount.QueryGetAccountArgs) {
    return this.doQuery<QueryAccount.GET_ACCOUNT_RESULT_TYPE, QueryAccount.QueryGetAccountArgs>(
      this.endPoint,
      QueryAccount.getAccountQuery,
      args,
    );
  }

  public async getAccountName(args: QueryAccount.QueryGetAccountNameArgs) {
    return this.doQuery<
      QueryAccount.GET_ACCOUNT_NAME_RESULT_TYPE,
      QueryAccount.QueryGetAccountNameArgs
    >(this.endPoint, QueryAccount.getAccountNameQuery, args);
  }

  public async updateAccount(args: QueryAccount.MutationUpdateAccountArgs) {
    return this.doMutate<
      QueryAccount.UPDATE_ACCOUNT_RESULT_TYPE,
      QueryAccount.MutationUpdateAccountArgs
    >(this.endPoint, QueryAccount.updateAccountMutation, args);
  }

  public async getMember(args: QueryAccount.QueryGetMemberArgs) {
    return this.doQuery<QueryAccount.GET_MEMBER_RESULT_TYPE, QueryAccount.QueryGetMemberArgs>(
      this.endPoint,
      QueryAccount.getMemberQuery,
      args,
    );
  }

  public async listMembers(args: QueryAccount.QueryToListMembersArgs) {
    return this.doQuery<QueryAccount.LIST_MEMBERS_RESULT_TYPE, QueryAccount.QueryToListMembersArgs>(
      this.endPoint,
      QueryAccount.listMembersQuery,
      args,
    );
  }

  public async addMember(args: QueryAccount.MutationAddMemberArgs) {
    return this.doMutate<QueryAccount.ADD_MEMBER_RESULT_TYPE, QueryAccount.MutationAddMemberArgs>(
      this.endPoint,
      QueryAccount.addMemberMutation,
      args,
    );
  }

  public async updateMember(args: QueryAccount.MutationUpdateMemberArgs) {
    return this.doMutate<
      QueryAccount.EDIT_MEMBER_RESULT_TYPE,
      QueryAccount.MutationUpdateMemberArgs
    >(this.endPoint, QueryAccount.editMemberMutation, args);
  }

  public async removeMember(args: QueryAccount.MutationRemoveMemberArgs) {
    return this.doMutate<
      QueryAccount.REMOVE_MEMBER_RESULT_TYPE,
      QueryAccount.MutationRemoveMemberArgs
    >(this.endPoint, QueryAccount.removeMemberMutation, args);
  }

  public async changePrimaryOwner(args: QueryAccount.MutationChangePrimaryOwnerArgs) {
    return this.doMutate<
      QueryAccount.CHANGE_PRIMARY_OWNER_RESULT_TYPE,
      QueryAccount.MutationChangePrimaryOwnerArgs
    >(this.endPoint, QueryAccount.changePrimaryOwnerMutation, args);
  }

  public async listProviders(args: QueryListProvidersArgs) {
    return this.doQuery<LIST_PROVIDERS_RESULT_TYPE, QueryListProvidersArgs>(
      this.endPoint,
      listProvidersQuery,
      args,
    );
  }

  public async listNetworks(args: QueryListNetworksArgs) {
    return this.doQuery<LIST_NETWORKS_RESULT_TYPE, QueryListNetworksArgs>(
      this.endPoint,
      listNetworksQuery,
      args,
    );
  }

  public async getNetwork(args: QueryGetNetworkArgs) {
    return this.doQuery<GET_NETWORK_RESULT_TYPE, QueryGetNetworkArgs>(
      this.endPoint,
      getNetworkQuery,
      args,
    );
  }

  public async getNetworkRole(args: QueryGetNetworkRoleArgs) {
    return this.doQuery<GET_NETWORK_ROLE_RESULT_TYPE, QueryGetNetworkRoleArgs>(
      this.endPoint,
      getNetworkRoleQuery,
      args,
    );
  }

  public async createNetwork(args: MutationCreateNetworkArgs) {
    return this.doMutate<CREATE_NETWORK_RESULT_TYPE, MutationCreateNetworkArgs>(
      this.endPoint,
      createNetworkMutation,
      args,
    );
  }

  public async updateNetwork(args: MutationUpdateNetworkArgs) {
    return this.doMutate<UPDATE_NETWORK_RESULT_TYPE, MutationUpdateNetworkArgs>(
      this.endPoint,
      updateNetworkMutation,
      args,
    );
  }

  public async destroyNetwork(args: MutationDestroyNetworkArgs) {
    return this.doMutate<DESTROY_NETWORK_RESULT_TYPE, MutationDestroyNetworkArgs>(
      this.endPoint,
      destroyNetworkMutation,
      args,
    );
  }

  public async createCluster(args: MutationCreateClusterArgs) {
    return this.doMutate<CREATE_CLUSTER_RESULT_TYPE, MutationCreateClusterArgs>(
      this.endPoint,
      createClusterMutation,
      args,
    );
  }

  public async updateCluster(args: MutationUpdateClusterArgs) {
    return this.doMutate<UPDATE_CLUSTER_RESULT_TYPE, MutationUpdateClusterArgs>(
      this.endPoint,
      updateClusterMutation,
      args,
    );
  }

  public async deleteCluster(args: MutationDeleteClusterArgs) {
    return this.doMutate<DELETE_CLUSTER_RESULT_TYPE, MutationDeleteClusterArgs>(
      this.endPoint,
      deleteClusterMutation,
      args,
    );
  }

  public async createNode(args: MutationCreateNodeArgs) {
    return this.doMutate<CREATE_NODE_RESULT_TYPE, MutationCreateNodeArgs>(
      this.endPoint,
      createNodeMutation,
      args,
    );
  }

  public async updateNode(args: MutationUpdateNodeArgs) {
    return this.doMutate<UPDATE_NODE_RESULT_TYPE, MutationUpdateNodeArgs>(
      this.endPoint,
      updateNodeMutation,
      args,
    );
  }

  public async deleteNode(args: MutationDeleteNodeArgs) {
    return this.doMutate<DELETE_NODE_RESULT_TYPE, MutationDeleteNodeArgs>(
      this.endPoint,
      deleteNodeMutation,
      args,
    );
  }

  public async controlNode(args: MutationControlNodeArgs) {
    return this.doMutate<CONTROL_NODE_RESULT_TYPE, MutationControlNodeArgs>(
      this.endPoint,
      controlNodeMutation,
      args,
    );
  }

  public async controlBlockExplorer(args: MutationControlBlockExplorerArgs) {
    return this.doMutate<CONTROL_BLOCK_EXPLORER_RESULT_TYPE, MutationControlBlockExplorerArgs>(
      this.endPoint,
      controlBlockExplorerMutation,
      args,
    );
  }

  public async getNodeMetrics(args: QueryGetNodeMetricsArgs) {
    return this.doQuery<GET_NODE_METRICS_RESULT_TYPE, QueryGetNodeMetricsArgs>(
      this.endPoint,
      getNodeMetricsQuery,
      args,
    );
  }

  public async getNodeLog(args: QueryGetNodeLogArgs) {
    return this.doQuery<GET_NODE_LOG_RESULT_TYPE, QueryGetNodeLogArgs>(
      this.endPoint,
      getNodeLogQuery,
      args,
    );
  }

  public async getExplorerMetrics(args: QueryGetExplorerMetricsArgs) {
    return this.doQuery<GET_EXPLORER_METRICS_RESULT_TYPE, QueryGetExplorerMetricsArgs>(
      this.endPoint,
      getExplorerMetricsQuery,
      args,
    );
  }

  public async getExplorerLog(args: QueryGetExplorerLogArgs) {
    return this.doQuery<GET_EXPLORER_LOG_RESULT_TYPE, QueryGetExplorerLogArgs>(
      this.endPoint,
      getExplorerLogQuery,
      args,
    );
  }

  public async grantNetworkRole(args: MutationGrantNetworkRoleArgs) {
    return this.doMutate<GRANT_NETWORK_ROLE_RESULT_TYPE, MutationGrantNetworkRoleArgs>(
      this.endPoint,
      grantNetworkRoleMutation,
      args,
    );
  }

  public async revokeNetworkRole(args: MutationRevokeNetworkRoleArgs) {
    return this.doMutate<REVOKE_NETWORK_ROLE_RESULT_TYPE, MutationRevokeNetworkRoleArgs>(
      this.endPoint,
      revokeNetworkRoleMutation,
      args,
    );
  }

  public async listNetworkAccesses(args: QueryListNetworkAccessesArgs) {
    return this.doQuery<LIST_NETWORK_ACCESSES_RESULT_TYPE, QueryListNetworkAccessesArgs>(
      this.endPoint,
      listNetworkAccessesQuery,
      args,
    );
  }

  public async listAlerts(args: QueryListAlertsArgs) {
    return this.doQuery<LIST_ALERTS_RESULT_TYPE, QueryListAlertsArgs>(
      this.endPoint,
      listAlertsQuery,
      args,
    );
  }

  public async setAlertRead(args: MutationSetAlertReadArgs) {
    return this.doMutate<SET_ALERT_READ_RESULT_TYPE, MutationSetAlertReadArgs>(
      this.endPoint,
      setAlertReadMutation,
      args,
    );
  }

  public async setAllAlertsRead(args: MutationSetAllAlertsReadArgs) {
    return this.doMutate<SET_ALL_ALERTS_READ_RESULT_TYPE, MutationSetAllAlertsReadArgs>(
      this.endPoint,
      setAllAlertsReadMutation,
      args,
    );
  }

  public async sendInvitation(args: QueryInvitation.MutationSendInvitationArgs) {
    return this.doMutate<
      QueryInvitation.SEND_INVITATION_RESULT_TYPE,
      QueryInvitation.MutationSendInvitationArgs
    >(this.endPoint, QueryInvitation.sendInvitationMutation, args);
  }

  public async listInvitations(args: QueryInvitation.QueryListInvitationsArgs) {
    return this.doQuery<
      QueryInvitation.LIST_INVITATIONS_RESULT_TYPE,
      QueryInvitation.QueryListInvitationsArgs
    >(this.endPoint, QueryInvitation.listInvitationsQuery, args);
  }

  public async responseInvitation(args: QueryInvitation.MutationResponseInvitationArgs) {
    return this.doMutate<
      QueryInvitation.RESPONSE_INVITATION_RESULT_TYPE,
      QueryInvitation.MutationResponseInvitationArgs
    >(this.endPoint, QueryInvitation.responseInvitationMutation, args);
  }

  public async cancelInvitation(args: QueryInvitation.MutationCancelInvitationArgs) {
    return this.doMutate<
      QueryInvitation.CANCEL_INVITATION_RESULT_TYPE,
      QueryInvitation.MutationCancelInvitationArgs
    >(this.endPoint, QueryInvitation.cancelInvitationMutation, args);
  }

  public async findClusters(args: QueryFindClustersArgs) {
    return this.doQuery<FIND_CLUSTERS_RESULT_TYPE, QueryFindClustersArgs>(
      this.endPoint,
      findClustersQuery,
      args,
    );
  }

  public async findNodes(args: QueryFindNodesArgs) {
    return this.doQuery<FIND_NODES_RESULT_TYPE, QueryFindNodesArgs>(
      this.endPoint,
      findNodesQuery,
      args,
    );
  }

  public async createAccount(args: QueryAccount.MutationCreateAccountArgs) {
    return this.doMutate<
      QueryAccount.CREATE_ACCOUNT_RESULT_TYPE,
      QueryAccount.MutationCreateAccountArgs
    >(this.endPoint, QueryAccount.createAccountMutation, args);
  }

  public async removeAccount(args: QueryAccount.MutationRemoveAccountArgs) {
    return this.doMutate<
      QueryAccount.REMOVE_ACCOUNT_RESULT_TYPE,
      QueryAccount.MutationRemoveAccountArgs
    >(this.endPoint, QueryAccount.removeAccountMutation, args);
  }

  public async getSigners(args: QueryProposal.QueryGetSignersArgs) {
    return this.doQuery<QueryProposal.GET_SIGNERS_RESULT_TYPE, QueryProposal.QueryGetSignersArgs>(
      this.endPoint,
      QueryProposal.getSignersQuery,
      args,
    );
  }

  public async getProposalStatus(args: QueryProposal.QueryGetProposalStatusArgs) {
    return this.doQuery<
      QueryProposal.GET_PROPOSAL_STATUS_RESULT_TYPE,
      QueryProposal.QueryGetProposalStatusArgs
    >(this.endPoint, QueryProposal.getProposalStatusQuery, args);
  }

  public async listActiveLicenses(args: QueryPayment.QueryListActiveLicensesArgs) {
    return this.doQuery<
      QueryPayment.LIST_ACTIVE_LICENSES_RESULT_TYPE,
      QueryPayment.QueryListActiveLicensesArgs
    >(this.endPoint, QueryPayment.listActiveLicensesQuery, args);
  }

  public async listActiveLicensesSummary(args: QueryPayment.QueryListActiveLicensesSummaryArgs) {
    return this.doQuery<
      QueryPayment.LIST_ACTIVE_LICENSES_SUMMARY_RESULT_TYPE,
      QueryPayment.QueryListActiveLicensesSummaryArgs
    >(this.endPoint, QueryPayment.listActiveLicensesSummaryQuery, args);
  }

  public async listAvailableLicenses(args: QueryPayment.QueryListAvailableLicensesArgs) {
    return this.doQuery<
      QueryPayment.LIST_AVAILABLE_LICENSES_RESULT_TYPE,
      QueryPayment.QueryListAvailableLicensesArgs
    >(this.endPoint, QueryPayment.listAvailableLicensesQuery, args);
  }

  public async listInsufficientLicenses(args: QueryPayment.QueryListInsufficientLicensesArgs) {
    return this.doQuery<
      QueryPayment.LIST_INSUFFICIENT_LICENSES_RESULT_TYPE,
      QueryPayment.QueryListInsufficientLicensesArgs
    >(this.endPoint, QueryPayment.listInsufficientLicensesQuery, args);
  }

  public async listInsufficientClusterLicenses(
    args: QueryPayment.QueryListInsufficientClusterLicensesArgs,
  ) {
    return this.doQuery<
      QueryPayment.LIST_INSUFFICIENT_CLUSTER_LICENSES_RESULT_TYPE,
      QueryPayment.QueryListInsufficientClusterLicensesArgs
    >(this.endPoint, QueryPayment.listInsufficientClusterLicensesQuery, args);
  }

  public async listInsufficientClusterTypeLicenses(
    args: QueryListInsufficientClusterTypeLicensesArgs,
  ) {
    return this.doQuery<
      QueryPayment.LIST_INSUFFICIENT_CLUSTER_TYPE_LICENSES_RESULT_TYPE,
      QueryListInsufficientClusterTypeLicensesArgs
    >(this.endPoint, QueryPayment.listInsufficientClusterTypeLicensesQuery, args);
  }

  public async listInsufficientNodeLicenses(
    args: QueryPayment.QueryListInsufficientNodeLicensesArgs,
  ) {
    return this.doQuery<
      QueryPayment.LIST_INSUFFICIENT_NODE_LICENSES_RESULT_TYPE,
      QueryPayment.QueryListInsufficientNodeLicensesArgs
    >(this.endPoint, QueryPayment.listInsufficientNodeLicensesQuery, args);
  }

  public async listInsufficientNodeServerLicenses(
    args: QueryPayment.QueryListInsufficientNodeServerLicensesArgs,
  ) {
    return this.doQuery<
      QueryPayment.LIST_INSUFFICIENT_NODE_SERVER_LICENSES_RESULT_TYPE,
      QueryPayment.QueryListInsufficientNodeServerLicensesArgs
    >(this.endPoint, QueryPayment.listInsufficientNodeServerLicensesQuery, args);
  }

  public async listInsufficientVolumeLicenses(
    args: QueryPayment.QueryListInsufficientVolumeLicensesArgs,
  ) {
    return this.doQuery<
      QueryPayment.LIST_INSUFFICIENT_VOLUME_LICENSES_RESULT_TYPE,
      QueryPayment.QueryListInsufficientVolumeLicensesArgs
    >(this.endPoint, QueryPayment.listInsufficientVolumeLicensesQuery, args);
  }

  public async estimateLicenseFee(args: QueryPayment.QueryEstimateLicenseFeeArgs) {
    return this.doQuery<
      QueryPayment.ESTIMATE_LICENSE_FEE_RESULT_TYPE,
      QueryPayment.QueryEstimateLicenseFeeArgs
    >(this.endPoint, QueryPayment.estimateLicenseFeeQuery, args);
  }

  public async listBillings(args: QueryPayment.QueryListBillingsArgs) {
    return this.doQuery<QueryPayment.LIST_BILLINGS_RESULT_TYPE, QueryPayment.QueryListBillingsArgs>(
      this.endPoint,
      QueryPayment.listBillingsQuery,
      args,
    );
  }

  public async listCoupons(args: QueryPayment.QueryListCouponsArgs) {
    return this.doQuery<QueryPayment.LIST_COUPONS_RESULT_TYPE, QueryPayment.QueryListCouponsArgs>(
      this.endPoint,
      QueryPayment.listCouponsQuery,
      args,
    );
  }

  public async listCards(args: QueryPayment.QueryListCardsArgs) {
    return this.doQuery<QueryPayment.LIST_CARDS_RESULT_TYPE, QueryPayment.QueryListCardsArgs>(
      this.endPoint,
      QueryPayment.listCardsQuery,
      args,
    );
  }

  public async registerCard(args: QueryPayment.MutationRegisterCardArgs) {
    return this.doMutate<
      QueryPayment.REGISTER_CARD_RESULT_TYPE,
      QueryPayment.MutationRegisterCardArgs
    >(this.endPoint, QueryPayment.registerCardMutation, args);
  }

  public async cancelPurchaseLicense(args: QueryPayment.MutationCancelPurchaseLicenseArgs) {
    return this.doMutate<
      QueryPayment.CANCEL_PURCHASE_LICENSE_RESULT_TYPE,
      QueryPayment.MutationCancelPurchaseLicenseArgs
    >(this.endPoint, QueryPayment.cancelPurchaseLicenseMutation, args);
  }

  public async purchaseLicenses(args: QueryPayment.MutationPurchaseLicensesArgs) {
    return this.doMutate<
      QueryPayment.PURCHASE_LICENSES_RESULT_TYPE,
      QueryPayment.MutationPurchaseLicensesArgs
    >(this.endPoint, QueryPayment.purchaseLicensesMutation, args);
  }

  public async listEndpointRestrictions(args: QueryListEndpointRestrictionsArgs) {
    return this.doMutate<LIST_ENDPOINT_RESTRICTIONS_RESULT_TYPE, QueryListEndpointRestrictionsArgs>(
      this.endPoint,
      listEndpointRestrictionsQuery,
      args,
    );
  }

  public async listExplorerRestrictions(args: QueryListExplorerRestrictionsArgs) {
    return this.doMutate<LIST_EXPLORER_RESTRICTIONS_RESULT_TYPE, QueryListExplorerRestrictionsArgs>(
      this.endPoint,
      listExplorerRestrictionsQuery,
      args,
    );
  }

  public async listNodeRestrictions(args: QueryListNodeRestrictionsArgs) {
    return this.doMutate<LIST_NODE_RESTRICTIONS_RESULT_TYPE, QueryListNodeRestrictionsArgs>(
      this.endPoint,
      listNodeRestrictionsQuery,
      args,
    );
  }

  public async setEndpointRestriction(args: MutationSetEndpointRestrictionArgs) {
    return this.doMutate<SET_ENDPOINT_RESTRICTION_RESULT_TYPE, MutationSetEndpointRestrictionArgs>(
      this.endPoint,
      setEndpointRestrictionMutation,
      args,
    );
  }

  public async setExplorerRestriction(args: MutationSetExplorerRestrictionArgs) {
    return this.doMutate<SET_EXPLORER_RESTRICTION_RESULT_TYPE, MutationSetExplorerRestrictionArgs>(
      this.endPoint,
      setExplorerRestrictionMutation,
      args,
    );
  }

  public async setNodeRestriction(args: MutationSetNodeRestrictionArgs) {
    return this.doMutate<SET_NODE_RESTRICTION_RESULT_TYPE, MutationSetNodeRestrictionArgs>(
      this.endPoint,
      setNodeRestrictionMutation,
      args,
    );
  }

  public async expandNodeVolume(args: MutationExpandNodeVolumeArgs) {
    return this.doMutate<EXPAND_NODE_VOLUME_RESULT_TYPE, MutationExpandNodeVolumeArgs>(
      this.endPoint,
      expandNodeVolumeMutation,
      args,
    );
  }

  public async expandBlockExplorerVolume(args: MutationExpandBlockExplorerVolumeArgs) {
    return this.doMutate<
      EXPAND_BLOCK_EXPLORER_VOLUME_RESULT_TYPE,
      MutationExpandBlockExplorerVolumeArgs
    >(this.endPoint, expandBlockExplorerVolumeMutation, args);
  }

  public async sendAllEtherFromNode(args: MutationSendAllEtherFromNodeArgs) {
    return this.doMutate<SEND_ALL_ETHER_FROM_NODE_RESULT_TYPE, MutationSendAllEtherFromNodeArgs>(
      this.endPoint,
      sendAllEtherFromNodeMutation,
      args,
    );
  }

  public async estimateTransferGas(args: QueryEstimateTransferGasArgs) {
    return this.doQuery<ESTIMATE_TRANSFER_GAS_RESULT_TYPE, QueryEstimateTransferGasArgs>(
      this.endPoint,
      estimateTransferGasQuery,
      args,
    );
  }

  public async registerAddress(args: QueryAccount.MutationRegisterAddressArgs) {
    return this.doMutate<
      QueryAccount.REGISTER_ADDRESS_RESULT_TYPE,
      QueryAccount.MutationRegisterAddressArgs
    >(this.endPoint, QueryAccount.registerAddressMutation, args);
  }

  public async updateContact(args: QueryAccount.MutationUpdateContactArgs) {
    return this.doMutate<
      QueryAccount.UPDATE_CONTACT_RESULT_TYPE,
      QueryAccount.MutationUpdateContactArgs
    >(this.endPoint, QueryAccount.updateContactMutation, args);
  }

  public async getGeolocation() {
    return this.doQuery<QueryGeolocation.GET_GEOLOCATION_RESULT_TYPE, any>(
      this.endPoint,
      QueryGeolocation.getGeolocationQuery,
    );
  }

  public async checkClusterRemovalStatus(args: QueryCheckClusterRemovalStatusArgs) {
    return this.doQuery<CLUSTER_REMOVAL_STATUS_RESULT_TYPE, QueryCheckClusterRemovalStatusArgs>(
      this.endPoint,
      checkClusterRemovalStatusQuery,
      args,
    );
  }

  public async checkNodeRemovalStatus(args: QueryCheckNodeRemovalStatusArgs) {
    return this.doQuery<NODE_REMOVAL_STATUS_RESULT_TYPE, QueryCheckNodeRemovalStatusArgs>(
      this.endPoint,
      checkNodeRemovalStatusQuery,
      args,
    );
  }

  public async getTxpoolStatus(args: QueryGetTxpoolStatusArgs) {
    return this.doQuery<GET_TXPOOL_STATUS_RESULT_TYPE, QueryGetTxpoolStatusArgs>(
      this.endPoint,
      getTxpoolStatusQuery,
      args,
    );
  }

  public async listConsortiums(args: QueryNetwork.QueryListConsortiumsArgs) {
    return this.doQuery<
      QueryNetwork.LIST_CONSORTIUMS_RESULT_TYPE,
      QueryNetwork.QueryListConsortiumsArgs
    >(this.endPoint, QueryNetwork.listConsortiumsQuery, args);
  }

  public async withdrawConsortium(args: QueryNetwork.MutationWithdrawConsortiumArgs) {
    return this.doMutate<
      QueryNetwork.WITHDRAW_CONSORTIUM_RESULT_TYPE,
      QueryNetwork.MutationWithdrawConsortiumArgs
    >(this.endPoint, QueryNetwork.withdrawConsortiumMutation, args);
  }

  public async updateConsortiumRole(args: QueryNetwork.MutationUpdateConsortiumRoleArgs) {
    return this.doMutate<
      QueryNetwork.UPDATE_CONSORTIUM_ROLE_RESULT_TYPE,
      QueryNetwork.MutationUpdateConsortiumRoleArgs
    >(this.endPoint, QueryNetwork.updateConsortiumRoleMutation, args);
  }

  public async updateNetworkOption(args: QueryNetwork.MutationUpdateNetworkOptionArgs) {
    return this.doMutate<
      QueryNetwork.UPDATE_NETWORK_OPTION_RESULT_TYPE,
      QueryNetwork.MutationUpdateNetworkOptionArgs
    >(this.endPoint, QueryNetwork.updateNetworkOptionMutation, args);
  }

  public async listCustomDomains(args: QueryNetwork.QueryListCustomDomainsArgs) {
    return this.doQuery<
      QueryNetwork.LIST_CUSTOM_DOMAINS_RESULT_TYPE,
      QueryNetwork.QueryListCustomDomainsArgs
    >(this.endPoint, QueryNetwork.listCustomDomainsQuery, args);
  }

  public async addCustomDomain(args: MutationAddCustomDomainArgs) {
    return this.doMutate<ADD_CUSTOM_DOMAIN_RESULT_TYPE, MutationAddCustomDomainArgs>(
      this.endPoint,
      addCustomDomainMutation,
      args,
    );
  }

  public async removeCustomDomain(args: MutationRemoveCustomDomainArgs) {
    return this.doMutate<REMOVE_CUSTOM_DOMAIN_RESULT_TYPE, MutationRemoveCustomDomainArgs>(
      this.endPoint,
      removeCustomDomainMutation,
      args,
    );
  }

  public async checkCustomDomainStatus(args: MutationCheckCustomDomainStatusArgs) {
    return this.doMutate<
      CHECK_CUSTOM_DOMAIN_STATUS_RESULT_TYPE,
      MutationCheckCustomDomainStatusArgs
    >(this.endPoint, checkCustomDomainStatusMutation, args);
  }

  public async createBlockExplorer(args: MutationToCreateBlockExplorerArgs) {
    return this.doMutate<ADD_BLOCK_EXPLORER_RESULT_TYPE, MutationToCreateBlockExplorerArgs>(
      this.endPoint,
      addBlockExplorerMutation,
      args,
    );
  }

  public async deleteBlockExplorer(args: MutationDeleteBlockExplorerArgs) {
    return this.doMutate<REMOVE_BLOCK_EXPLORER_RESULT_TYPE, MutationDeleteBlockExplorerArgs>(
      this.endPoint,
      deleteBlockExplorer,
      args,
    );
  }

  public async listInsufficientBlockExplorerLicenses(
    args: QueryPayment.QueryListInsufficientBlockExplorerLicensesArgs,
  ) {
    return this.doMutate<
      QueryPayment.LIST_INSUFFICIENT_BLOCK_EXPLORER_LICENSES_RESULT_TYPE,
      QueryPayment.QueryListInsufficientBlockExplorerLicensesArgs
    >(this.endPoint, QueryPayment.listInsufficientBlockExplorerLicenses, args);
  }

  public async acceptTermsOfService(args: QueryAccount.MutationAcceptTermsOfServiceArgs) {
    return this.doMutate<
      QueryAccount.ACCEPT_TERMS_OF_SERVICE_RESULT_TYPE,
      QueryAccount.MutationAcceptTermsOfServiceArgs
    >(this.endPoint, QueryAccount.acceptTermsOfServiceMutaion, args);
  }

  public async updateNodeVersion(args: MutationUpdateNodeVersionArgs) {
    return this.doMutate<
      QueryNetwork.UPDATE_NODE_VERSION_RESULT_TYPE,
      MutationUpdateNodeVersionArgs
    >(this.endPoint, QueryNetwork.updateNodeVersion, args);
  }

  public async updateBlockExplorer(args: MutationUpdateBlockExplorerArgs) {
    return this.doMutate<
      QueryNetwork.UPDATE_BLOCK_EXP_RESULT_TYPE,
      MutationUpdateBlockExplorerArgs
    >(this.endPoint, QueryNetwork.updateBlockExplorer, args);
  }

  public async updateNodeExplorerVersion(args: MutationUpdateNodeExplorerVersionArgs) {
    return this.doMutate<
      QueryNetwork.UPDATE_NODE_EXP_RESULT_TYPE,
      MutationUpdateNodeExplorerVersionArgs
    >(this.endPoint, QueryNetwork.updateNodeExplorerVersion, args);
  }

  public async createHardForkProposal(args: MutationCreateHardForkProposalArgs) {
    return this.doMutate<
      QueryNetwork.SCHEDULE_HARD_FORK_RESULT_TYPE,
      MutationCreateHardForkProposalArgs
    >(this.endPoint, QueryNetwork.createHardForkProposal, args);
  }

  public async voteHardForkProposal(args: MutationVoteHardForkProposalArgs) {
    return this.doMutate<
      QueryNetwork.VOTE_HARD_FORK_SCHEDULE_RESULT_TYPE,
      MutationVoteHardForkProposalArgs
    >(this.endPoint, QueryNetwork.voteHardForkProposal, args);
  }

  public async listHardForkProposals(args: QueryListHardForkProposalsArgs) {
    return this.doMutate<
      QueryNetwork.LIST_HARD_FORK_PROPOSAL_RESULT_TYPE,
      QueryListHardForkProposalsArgs
    >(this.endPoint, QueryNetwork.listHardForkProposals, args);
  }

  public async applyHardForkToNode(args: MutationApplyHardForkToNodeArgs) {
    return this.doMutate<
      QueryNetwork.APPLY_HARD_FORK_TO_NODE_RESULT_TYPE,
      MutationApplyHardForkToNodeArgs
    >(this.endPoint, QueryNetwork.applyHardForkToNode, args);
  }

  public async applyHardForkToBlockExplorer(args: MutationApplyHardForkToBlockExplorerArgs) {
    return this.doMutate<
      QueryNetwork.APPLY_HARD_FORK_TO_EXPLORER_RESULT_TYPE,
      MutationApplyHardForkToBlockExplorerArgs
    >(this.endPoint, QueryNetwork.applyHardForkToBlockExplorer, args);
  }

  public async retryApplyHardForkToNetwork(args: MutationRetryApplyHardForkToNetworkArgs) {
    return this.doMutate<
      QueryNetwork.RETRY_APPLY_HARD_FORK_TO_NETWORK_RESULT_TYPE,
      MutationRetryApplyHardForkToNetworkArgs
    >(this.endPoint, QueryNetwork.retryApplyHardForkToNetwork, args);
  }

  public async updateBlockGasLimit(args: MutationUpdateBlockGasLimitArgs) {
    return this.doMutate<
      QueryNetwork.UPDATE_GAS_LIMIT_RESULT_TYPE,
      MutationUpdateBlockGasLimitArgs
    >(this.endPoint, QueryNetwork.updateBlockGasLimit, args);
  }

  public async updateNodeInstanceType(args: MutationUpdateNodeInstanceTypeArgs) {
    return this.doMutate<
      QueryNetwork.UPDATE_NODE_INSTANCE_RESULT_TYPE,
      MutationUpdateNodeInstanceTypeArgs
    >(this.endPoint, QueryNetwork.updateNodeInstanceType, args);
  }

  public async updateBlockExplorerInstanceType(args: MutationUpdateBlockExplorerInstanceTypeArgs) {
    return this.doMutate<
      QueryNetwork.UPDATE_BLOCK_EXPLORER_INSTANCE_RESULT_TYPE,
      MutationUpdateBlockExplorerInstanceTypeArgs
    >(this.endPoint, QueryNetwork.updateBlockExplorerInstanceType, args);
  }

  public async listInsufficientBlockExpInstanceLicenses(
    args: QueryListInsufficientBlockExpInstanceLicensesArgs,
  ) {
    return this.doMutate<
      QueryPayment.LIST_INSUFFICIENT_BLOCK_EXP_INSTANCE_LICENSES_RESULT_TYPE,
      QueryListInsufficientBlockExpInstanceLicensesArgs
    >(this.endPoint, QueryPayment.listInsufficientBlockExpInstanceLicenses, args);
  }

  public async getGenesisJson(args: QueryGetGenesisJsonArgs) {
    return this.doQuery<QueryNetwork.GET_GENESIS_JSON_RESULT_TYPE, QueryGetGenesisJsonArgs>(
      this.endPoint,
      QueryNetwork.getGenesisJsonQuery,
      args,
    );
  }

  public async getGenesisJsonInExplorer(args: QueryGetGenesisJsonInExplorerArgs) {
    return this.doQuery<
      QueryNetwork.GET_GENESIS_JSON_IN_EXPLORER_RESULT_TYPE,
      QueryGetGenesisJsonInExplorerArgs
    >(this.endPoint, QueryNetwork.getGenesisJsonInExplorerQuery, args);
  }

  public async sendProposal(args: MutationSendProposalArgs) {
    return this.doMutate<QueryProposal.SEND_PROPOSAL_RESULT_TYPE, MutationSendProposalArgs>(
      this.endPoint,
      QueryProposal.sendProposal,
      args,
    );
  }

  public async discardProposal(args: MutationDiscardProposalArgs) {
    return this.doMutate<QueryProposal.DISCARD_PROPOSAL_RESULT_TYPE, MutationDiscardProposalArgs>(
      this.endPoint,
      QueryProposal.discardProposal,
      args,
    );
  }

  public async listProposalHistory(args: QueryListProposalHistoryArgs) {
    return this.doMutate<
      QueryProposal.LIST_PROPOSAL_HISTORY_RESULT_TYPE,
      QueryListProposalHistoryArgs
    >(this.endPoint, QueryProposal.listProposalHistoryQuery, args);
  }

  public async updateExternalClef(args: MutationUpdateExternalClefArgs) {
    return this.doMutate<
      QueryNetwork.UPDATE_EXTERNAL_CLEF_RESULT_TYPE,
      MutationUpdateExternalClefArgs
    >(this.endPoint, QueryNetwork.updateExternalClef, args);
  }

  public async refreshExternalClefConnection(args: MutationRefreshExternalClefConnectionArgs) {
    return this.doMutate<
      QueryNetwork.REFRESH_CONNECTION_TO_EXTERNAL_CLEF,
      MutationRefreshExternalClefConnectionArgs
    >(this.endPoint, QueryNetwork.refreshExternalClefConnection, args);
  }

  public async updateBlockExplorerVersion(args: MutationUpdateBlockExplorerVersionArgs) {
    return this.doMutate<
      QueryNetwork.UPDATE_BLOCK_EXPLORER_VERSION,
      MutationUpdateBlockExplorerVersionArgs
    >(this.endPoint, QueryNetwork.updateBlockExplorerVersion, args);
  }

  public async setEndpointListener(args: MutationSetEndpointListenerArgs) {
    return this.doMutate<QueryNetwork.SET_LOAD_BALANCER_LISTENER, MutationSetEndpointListenerArgs>(
      this.endPoint,
      QueryNetwork.setEndpointListener,
      args,
    );
  }

  public async updateRuleOfInternalClef(args: MutationUpdateRuleOfInternalClefArgs) {
    return this.doMutate<
      QueryNetwork.UPDATE_RULE_INTERNAL_CLEF,
      MutationUpdateRuleOfInternalClefArgs
    >(this.endPoint, QueryNetwork.updateRuleOfInternalClef, args);
  }

  public async migrateToInternalClefNode(args: MutationMigrateToInternalClefNodeArgs) {
    return this.doMutate<QueryNetwork.MIGRATE_TO_CLEF_NODE, MutationMigrateToInternalClefNodeArgs>(
      this.endPoint,
      QueryNetwork.migrateToInternalClefNode,
      args,
    );
  }

  public async migrateBootnode(args: MutationMigrateBootnodeArgs) {
    return this.doMutate<QueryNetwork.MIGRATE_BOOTNODE_RESULT_TYPE, MutationMigrateBootnodeArgs>(
      this.endPoint,
      QueryNetwork.migrateBootnode,
      args,
    );
  }

  public async associateStaticIpAddress(args: MutationAssociateStaticIpAddressArgs) {
    return this.doMutate<
      QueryNetwork.ASSOISIATE_IP_TO_ADDRESS_RESULT_TYPE,
      MutationAssociateStaticIpAddressArgs
    >(this.endPoint, QueryNetwork.associateStaticIpAddress, args);
  }

  public async listInsufficientStaticIpAddressLicenses(
    args: QueryListInsufficientStaticIpAddressLicensesArgs,
  ) {
    return this.doMutate<
      QueryPayment.LIST_INSUFFICIENT_ELASTIC_IP_LICENSES_RESULT_TYPE,
      QueryListInsufficientStaticIpAddressLicensesArgs
    >(this.endPoint, QueryPayment.listInsufficientStaticIpAddressLicenses, args);
  }

  public async disassociateStaticIpAddress(args: MutationDisassociateStaticIpAddressArgs) {
    return this.doMutate<
      QueryNetwork.DISASSOCIATE_IP_TO_ADDRESS_RESULT_TYPE,
      MutationDisassociateStaticIpAddressArgs
    >(this.endPoint, QueryNetwork.disassociateStaticIpAddress, args);
  }

  public async updateClusterType(args: MutationUpdateClusterTypeArgs) {
    return this.doMutate<
      QueryNetwork.UPDATE_CLUSTER_TYPE_RESULT_TYPE,
      MutationUpdateClusterTypeArgs
    >(this.endPoint, QueryNetwork.updateClusterType, args);
  }
}
