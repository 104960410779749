import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import {
  defaultFont,
  defaultFontMedium,
  defaultFontRegular,
} from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import SubmitButton from './submit-button';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomInputNumCommaFormat from './custom-input-num-comma-format';

import { useDispatch, useSelector } from 'react-redux';

import { Formik, Field, Form, FieldProps, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  pattensBlueColor,
  whiteColor,
  denimColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';
import CustomTextField from './custom-text-field';
import { ICluster } from '~/types/network-types';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import Grid from '@mui/material/Grid';
import ImgIcon from '~/components/common/img-icon';

interface IProps extends WithStyles<typeof styles> {
  networkUuid: string;
  cluster: ICluster;
  open: boolean;
  onClose: () => void;
}

type FormValues = {
  reCaptchaSecretKey: string;
  reCaptchaClientKey: string;
  walletConnectProjectId?: string;
  solidityScanChainId?: string;
  solidityScanToken?: string;
  rpcUrl?: string;
};

const UpdateBlockExplorerDialog = (props: IProps) => {
  const { classes, open, networkUuid, cluster, onClose } = props;
  const accountSeleted = useSelector((store: IStore) => store.appState.accountSeleted);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showClientKey, setShowClientKey] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);
  const [showWalletConnectProjectId, setShowWalletConnectProjectId] = useState(false);
  const [showSolidityScanToken, setShowSolidityScanToken] = useState(false);
  const [isShowAdvanced, setIsShowAdvanced] = useState(false);
  const initialValues: FormValues = {
    reCaptchaClientKey: '',
    reCaptchaSecretKey: '',
    walletConnectProjectId: '',
    solidityScanChainId: cluster.explorer?.blockscoutInfo.solidityScanChainId || '',
    solidityScanToken: '',
    rpcUrl: cluster.explorer?.blockscoutInfo.rpcUrl || '',
  };

  const validateSchema = Yup.object().shape<FormValues>(
    {
      reCaptchaClientKey: Yup.string().when('reCaptchaSecretKey', {
        is: (exists) => !!exists,
        then: Yup.string()
          .max(
            100,
            t('validate_maximum', {
              val: 100,
            }),
          )
          .required(t('required_field')),
        otherwise: Yup.string().notRequired(),
      }),
      reCaptchaSecretKey: Yup.string().when('reCaptchaClientKey', {
        is: (exists) => !!exists,
        then: Yup.string()
          .max(
            100,
            t('validate_maximum', {
              val: 100,
            }),
          )
          .required(t('required_field')),
        otherwise: Yup.string().notRequired(),
      }),
      walletConnectProjectId: Yup.string()
        .max(
          100,
          t('validate_maximum', {
            val: 100,
          }),
        )
        .notRequired(),
      rpcUrl: Yup.string().url().notRequired(),
    },
    [['reCaptchaClientKey', 'reCaptchaSecretKey']],
  );

  const toggleShowClientKey = useCallback(() => {
    setShowClientKey((prevState) => !prevState);
  }, []);

  const toggleShowSecretKey = useCallback(() => {
    setShowSecretKey((prevState) => !prevState);
  }, []);

  const toggleShowWalletConnectProjectId = useCallback(() => {
    setShowWalletConnectProjectId((prevState) => !prevState);
  }, []);

  const toggleShowSolidityScanToken = useCallback(() => {
    setShowSolidityScanToken((prevState) => !prevState);
  }, []);

  const toggleAdvanced = useCallback(() => {
    setIsShowAdvanced((prevState) => !prevState);
  }, []);

  const reCaptchaSecretKeyField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <>
          <div className={classes.formLabelLine}>
            <div className={classes.formLabel}>{t('re_captcha_secret_key')}</div>
            {!!form.errors.reCaptchaSecretKey && form.touched.reCaptchaSecretKey && (
              <div className={classNames(classes.formLabel, classes.formError)}>
                {t(form.errors.reCaptchaSecretKey)}
              </div>
            )}
          </div>
          <div>
            <CustomTextField
              data-testid="re-captcha-secret-input"
              id="re-captcha-secret-key"
              placeholder={t('input_re_captcha_secret_key')}
              type={showSecretKey ? 'text' : 'password'}
              autoComplete="new-password"
              endAdornmentIconButton={
                showSecretKey ? (
                  <VisibilityIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )
              }
              onEndAdornmentClick={toggleShowSecretKey}
              {...field}
            />
          </div>
        </>
      );
    },
    [classes, showSecretKey, t, toggleShowSecretKey],
  );

  const reCaptchaClientKeyField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <>
          <div className={classes.formLabelLine}>
            <div className={classes.formLabel}>{t('re_captcha_client_key')}</div>
            {!!form.errors.reCaptchaClientKey && form.touched.reCaptchaClientKey && (
              <div className={classNames(classes.formLabel, classes.formError)}>
                {t(form.errors.reCaptchaClientKey)}
              </div>
            )}
          </div>
          <div>
            <CustomTextField
              id="re-captcha-site-key"
              data-testid="re-captcha-client-input"
              placeholder={t('input_re_captcha_client_key')}
              type={showClientKey ? 'text' : 'password'}
              autoComplete="new-password"
              endAdornmentIconButton={
                showClientKey ? (
                  <VisibilityIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )
              }
              onEndAdornmentClick={toggleShowClientKey}
              {...field}
            />
          </div>
        </>
      );
    },
    [classes, showClientKey, t, toggleShowClientKey],
  );

  const rpcEndpointField = ({ field, form }: FieldProps<FormValues>) => {
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classNames(classes.formLabel, classes.rpcEndpoint)}>
            {t('network_RPC_endpoint')}{' '}
            <Tooltip
              title={t('input_network_RPC_endpoint_description')}
              classes={{ tooltip: classes.tooltip }}
            >
              <HelpIcon style={{ marginLeft: 2, fontSize: '12px' }} />
            </Tooltip>
          </div>
          {!!form.errors.rpcUrl && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.rpcUrl}
            </div>
          )}
        </div>
        <div>
          <CustomTextField
            data-testid="rpc-endpoint-input"
            id="rpc-endpoint-field"
            placeholder={t('input_network_RPC_endpoint')}
            {...field}
          />
        </div>
      </>
    );
  };

  const walletConnectProjectIdField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <>
          <div className={classes.formLabelLine}>
            <div className={classes.formLabel}>{t('wallet_connect_project_id')}</div>
            {!!form.errors.walletConnectProjectId && form.touched.walletConnectProjectId && (
              <div className={classNames(classes.formLabel, classes.formError)}>
                {t(form.errors.walletConnectProjectId)}
              </div>
            )}
          </div>
          <div>
            <CustomTextField
              data-testid="wallet-connect-project-id-input"
              id="wallet-connect-project-id"
              placeholder={t('input_wallet_connect_project_id')}
              type={showWalletConnectProjectId ? 'text' : 'password'}
              autoComplete="new-password"
              endAdornmentIconButton={
                showWalletConnectProjectId ? (
                  <VisibilityIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )
              }
              onEndAdornmentClick={toggleShowWalletConnectProjectId}
              {...field}
            />
          </div>
        </>
      );
    },
    [classes, showWalletConnectProjectId, t, toggleShowWalletConnectProjectId],
  );

  const solidityScanChainIdField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <>
          <div className={classes.formLabelLine}>
            <div className={classes.formLabel}>{t('solidity_scan_chain_id')}</div>
            {!!form.errors.solidityScanChainId && form.touched.solidityScanChainId && (
              <div className={classNames(classes.formLabel, classes.formError)}>
                {form.errors.solidityScanChainId}
              </div>
            )}
          </div>
          <div>
            <CustomTextField
              data-testid="solidity-scan-chain-id-input"
              {...field}
              placeholder={t('input_solidity_scan_chain_id')}
              id="solidity-scan-chain-id"
            />
          </div>
        </>
      );
    },
    [classes.formError, classes.formLabel, classes.formLabelLine, t],
  );

  const solidityScanTokenField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <>
          <div className={classes.formLabelLine}>
            <div className={classes.formLabel}>{t('solidity_scan_token')}</div>
            {!!form.errors.solidityScanToken && form.touched.solidityScanToken && (
              <div className={classNames(classes.formLabel, classes.formError)}>
                {t(form.errors.solidityScanToken)}
              </div>
            )}
          </div>
          <div>
            <CustomTextField
              data-testid="solidity-scan-token-input"
              id="solidity-scan-token"
              placeholder={t('input_solidity_scan_token')}
              type={showSolidityScanToken ? 'text' : 'password'}
              autoComplete="new-password"
              endAdornmentIconButton={
                showSolidityScanToken ? (
                  <VisibilityIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )
              }
              onEndAdornmentClick={toggleShowSolidityScanToken}
              {...field}
            />
          </div>
        </>
      );
    },
    [classes, showSolidityScanToken, t, toggleShowSolidityScanToken],
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      const { setSubmitting } = formikActions;
      if (accountSeleted?.accountUuid) {
        dispatch(
          NetworkActions.updateBlockExplorer({
            input: {
              accountUuid: accountSeleted.accountUuid,
              networkUuid,
              clusterUuid: cluster.clusterUuid,
              reCaptchaClientKey: values.reCaptchaClientKey || undefined,
              reCaptchaSecretKey: values.reCaptchaSecretKey || undefined,
              walletConnectProjectId: values.walletConnectProjectId || undefined,
              rpcUrl: values.rpcUrl || undefined,
              solidityScanChainId: values.solidityScanChainId || undefined,
              solidityScanToken: values.solidityScanToken || undefined,
            },
          }),
        );
      }
      onClose();
      setSubmitting(false);
    },
    [accountSeleted?.accountUuid, cluster, dispatch, networkUuid, onClose],
  );

  useEffect(() => {
    return () => {
      onClose();
    };
  }, [onClose]);

  return (
    <>
      <CustomDialog open={open} onClose={onClose} scroll="body">
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={onSubmit}
          render={({ isValid, isSubmitting, values }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-update-block-exp-title">{t('update_block_exp')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div className={classes.formSection}>
                  <Grid container>
                    <Grid item md={6} className={classes.gridLeftItem}>
                      <Field name="reCaptchaClientKey" render={reCaptchaClientKeyField} />
                    </Grid>
                    <Grid item md={6} className={classes.gridRightItem}>
                      <Field name="reCaptchaSecretKey" render={reCaptchaSecretKeyField} />
                    </Grid>
                  </Grid>
                </div>
                {cluster.explorer?.blockscoutInfo.frontend && (
                  <>
                    <div className={classes.formSection}>
                      <Grid container>
                        <Grid item md={6} className={classes.gridLeftItem}>
                          <Field
                            name="walletConnectProjectId"
                            render={walletConnectProjectIdField}
                          />
                        </Grid>
                        <Grid item md={6} className={classes.gridRightItem}>
                          <Field name="rpcUrl" render={rpcEndpointField} />
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.advancedBtnArea}>
                      <div className={classes.separateLine}></div>
                      <div
                        id="member-node-add-separator"
                        className={classes.advancedBtn}
                        onClick={toggleAdvanced}
                      >
                        <ImgIcon
                          className={classes.advancedIcon}
                          imgUrl={
                            isShowAdvanced
                              ? '/images/icons/minus_ico.png'
                              : '/images/icons/add_ico.png'
                          }
                        />
                        {t('advanced_menu')}
                      </div>
                    </div>
                    {isShowAdvanced && (
                      <>
                        <div className={classes.formSection}>
                          <Grid container>
                            <Grid item md={6} className={classNames(classes.gridLeftItem)}>
                              <Field name="solidityScanChainId" render={solidityScanChainIdField} />
                            </Grid>
                            <Grid item md={6} className={classes.gridRightItem}>
                              <Field name="solidityScanToken" render={solidityScanTokenField} />
                            </Grid>
                          </Grid>
                        </div>
                      </>
                    )}
                  </>
                )}
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  data-testid="cancel-button"
                  id="member-update-block-exp-cancel"
                  disabled={isSubmitting}
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={onClose}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  data-testid="update-button"
                  id="member-update-block-exp-submit"
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  label={t('update')}
                  submittingLabel={t('updating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    </>
  );
};

const styles = createStyles({
  root: {},
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formControlLabel: {
    marginLeft: '-14px',
    '& .MuiCheckbox-root': {
      padding: '12px',
    },
  },
  formSection: {
    marginTop: 10,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  rpcEndpoint: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    backgroundColor: 'rgb(20, 26, 31)',
  },
  gridLeftItem: {
    paddingRight: 6,
  },
  gridRightItem: {
    paddingLeft: 6,
  },
  advancedBtnArea: {
    marginTop: 30,
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    height: 24,
  },
  separateLine: {
    height: 1,
    width: '100%',
    backgroundColor: pattensBlueColor,
  },
  advancedBtn: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'inline-block',
    backgroundColor: whiteColor,
    paddingLeft: 10,
    paddingRight: 10,
    ...defaultFont,
    fontSize: 16,
    color: denimColor,
    cursor: 'pointer',
  },
  advancedIcon: {
    marginRight: 5,
    width: 14,
  },
});

export default withStyles(styles)(UpdateBlockExplorerDialog);
