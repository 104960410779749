/* tslint:disable:max-file-line-count */
import { createTypeReducer } from '@gu-corp/redux-async-lib';

import * as AppActions from '~/stores/actions/app-action';
import * as NetworkActions from '~/stores/actions/network-action';
import * as AlertActions from '~/stores/actions/alert-action';
import * as PaymentAction from '~/stores/actions/payment-action';
import { ISnackBarState } from '~/types/app-types';
import { IAlert, IAlertList } from '~/types/alert-types';
import { IGeolocation } from '~/types/geolocation-types';
import { Profile, Account, IAccountMember } from '~/types/account-types';
import {
  INetwork,
  INetworkProvider,
  ICluster,
  IClusterParent,
  INode,
  INodeParent,
  InvitationList,
  IRestrictionNetwork,
  INetworkAccess,
  ICustomDomainInfo,
  ICustomDomainDetailInfo,
  ListProposalHistory,
} from '~/types/network-types';
import {
  IBilling,
  ICoupon,
  ILicenseSummary,
  ILicenseSummaryByBilling,
  ICardSummary,
} from '~/types/payment-types';
import {
  getBillingsStartDateDefault,
  getBillingsEndDateDefault,
  getCouponsStartDateDefault,
  getCouponsEndDateDefault,
  getNotificationsStartDateDefault,
  getNotificationsEndDateDefault,
  NOTIFICATIONS_PAGE_INDEX_DEFAULT,
  NOTIFICATIONS_PAGE_SIZE_DEFAULT,
  INVITATIONS_PAGE_INDEX_DEFAULT,
  INVITATIONS_PAGE_SIZE_DEFAULT,
  PROPOSALS_PAGE_INDEX_DEFAULT,
  PROPOSALS_PAGE_SIZE_DEFAULT,
} from '../../constants/store';
import { ListHardForkProposals } from '~/gapi/gtypes';

const toICluster = (data: IClusterParent): ICluster => {
  const { networkUuid, ...rest } = data;
  return rest;
};

const toINode = (data: INodeParent): INode => {
  const { networkUuid, clusterUuid, ...rest } = data;
  return rest;
};

export interface IState {
  profile?: Profile;
  listAccounts: Account[];
  accountSeleted?: Account;
  accountMembers?: IAccountMember[];
  snackBarState: ISnackBarState;
  networkSelected?: INetwork;
  networks?: INetwork[];
  networkAccesses?: INetworkAccess[];
  providers?: INetworkProvider[];
  alerts?: IAlert[];
  alertList: IAlertList;
  unreadAlertList: IAlertList;
  newUnreadAlertList: IAlertList;
  invitationList: InvitationList;
  isShowAdvancedInCreateNetwork: boolean;
  billings: IBilling;
  coupons: ICoupon;
  activeLicenses: ILicenseSummary[];
  activeLicensesSummary?: ILicenseSummaryByBilling;
  cardSummary?: ICardSummary[];
  restrictions: IRestrictionNetwork[];
  geolocation?: IGeolocation;
  customDomains: ICustomDomainDetailInfo[];
  customDomain?: ICustomDomainInfo;
  hardForkProposals?: ListHardForkProposals;
  listProposalHistory: ListProposalHistory;
}

export const initialState: IState = {
  profile: undefined,
  listAccounts: [],
  accountSeleted: undefined,
  accountMembers: undefined,
  snackBarState: {
    open: false,
    type: 'info',
    message: '',
  },
  networkSelected: undefined,
  networks: undefined,
  // consortiums: undefined,
  networkAccesses: undefined,
  providers: undefined,
  alerts: undefined,
  alertList: {
    pageInfo: {
      pageIndex: NOTIFICATIONS_PAGE_INDEX_DEFAULT,
      pageSize: NOTIFICATIONS_PAGE_SIZE_DEFAULT,
    },
    filter: {
      startDate: getNotificationsStartDateDefault(),
      endDate: getNotificationsEndDateDefault(),
    },
  },
  unreadAlertList: {
    pageInfo: { pageIndex: 0 },
  },
  newUnreadAlertList: {
    pageInfo: { pageIndex: 0 },
  },
  invitationList: {
    pageInfo: {
      pageIndex: INVITATIONS_PAGE_INDEX_DEFAULT,
      pageSize: INVITATIONS_PAGE_SIZE_DEFAULT,
    },
    isSent: false,
  },
  listProposalHistory: {
    pageInfo: {
      pageIndex: PROPOSALS_PAGE_INDEX_DEFAULT,
      pageSize: PROPOSALS_PAGE_SIZE_DEFAULT,
    },
    filter: {},
  },
  isShowAdvancedInCreateNetwork: false,
  billings: {
    startPeriod: getBillingsStartDateDefault(),
    endPeriod: getBillingsEndDateDefault(),
    details: undefined,
  },
  coupons: {
    startPeriod: getCouponsStartDateDefault(),
    endPeriod: getCouponsEndDateDefault(),
    details: undefined,
  },
  activeLicenses: [],
  activeLicensesSummary: undefined,
  cardSummary: undefined,
  restrictions: [],
  customDomains: [],
  customDomain: undefined,
};

const getSnackbarMessage = (state: IState, error?: Error, defaultValue?: string) =>
  error
    ? (error as any).localizedMessages
      ? (error as any).localizedMessages[state.profile?.preference.language || 'en']
      : error.message
    : defaultValue;

export const closeSnackBarReducer = AppActions.closeSnackBar.reducer<IState>((state, action) => ({
  snackBarState: {
    ...state.snackBarState,
    open: false,
  },
}));

export const openSnackBarReducer = AppActions.openSnackBar.reducer<IState>((state, action) => ({
  snackBarState: {
    ...action.payload,
    open: true,
  },
}));

export const getAccountReducer = AppActions.getAccount.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        type: 'error',
        open: true,
        message: getSnackbarMessage(state, action.payload, 'can_not_get_account'),
      },
    };
  }

  const account = action.payload.getAccount;
  const result: any = {};

  if (state.accountSeleted && state.accountSeleted.accountUuid === account.accountUuid) {
    result.accountSeleted = {
      ...account,
    };
  }

  if (state.listAccounts.length < 1) {
    result.listAccounts = [account];
  } else {
    const pos = state.listAccounts.findIndex((ac) => ac.accountUuid === account.accountUuid);
    const newList = (state.listAccounts || []).concat();

    if (pos >= 0) {
      newList[pos] = {
        ...account,
      };
    } else {
      newList.push(account);
    }

    result.listAccounts = newList;
  }

  return result;
});

export const getProfileReducer = AppActions.getProfile.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        type: 'error',
        open: true,
        message: getSnackbarMessage(state, action.payload, 'unknow_error_occured'),
      },
    };
  }

  const profile = action.payload.getProfile;
  if (profile) {
    let accountSeleted = profile.accounts.find(
      (account) => account.accountUuid === profile.preference.lastSelectedAccountUuid,
    );

    if (!accountSeleted && profile.accounts.length) {
      accountSeleted = profile.accounts[0];
    }

    return {
      profile,
      listAccounts: profile.accounts,
      accountSeleted,
      snackBarState: {
        type: 'success',
        open: true,
        message: 'Hello ' + profile.displayName,
      },
    };
  } else {
    return {
      profile,
    };
  }
});

export const updateSelectNetworkReducer = AppActions.selectNetwork.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'unknow_error_occured'),
        },
      };
    }
    return {
      profile: action.payload.selectNetwork,
    };
  },
);

export const updateProfileReducer = AppActions.updateProfile.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        type: 'error',
        open: true,
        message: getSnackbarMessage(state, action.payload, 'unknow_error_occured'),
      },
    };
  }

  if (!action.payload.updateProfile) {
    return {
      snackBarState: {
        type: 'error',
        open: true,
        message: 'unknow_error_occured',
      },
    };
  }

  return {
    profile: action.payload.updateProfile,
    snackBarState: {
      open: true,
      type: 'success',
      message: 'update_profile_successfully',
    },
  };
});

export const listMembersReducer = AppActions.listMembers.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_list_members'),
      },
    };
  }

  return {
    accountMembers: action.payload.listMembers,
  };
});

export const addMemberReducer = AppActions.addMember.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_add_member'),
      },
    };
  }

  return {
    snackBarState: {
      open: true,
      type: 'success',
      message: 'add_member_success',
    },
  };
});

export const updateMemberReducer = AppActions.updateMember.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_update_member'),
      },
    };
  }
  const updatedMember = action.payload.updateMember;

  return {
    accountMembers:
      state.accountMembers &&
      state.accountMembers.map((member) =>
        member.uid === updatedMember.uid ? updatedMember : member,
      ),
    snackBarState: {
      open: true,
      type: 'success',
      message: 'update_member_success',
    },
  };
});

export const removeMemberReducer = AppActions.removeMember.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_remove_member'),
      },
    };
  }
  const { uid } = action.meta.input;

  return {
    accountMembers:
      state.accountMembers && state.accountMembers.filter((member) => member.uid !== uid),
    snackBarState: {
      open: true,
      type: 'success',
      message: 'remove_member_success',
    },
  };
});

export const changePrimaryOwnerReducer = AppActions.changePrimaryOwner.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_change_primary_owner'),
        },
      };
    }
    const changePrimaryOwner = action.payload.changePrimaryOwner;

    return {
      accountMembers: changePrimaryOwner,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'change_primary_owner_success',
      },
    };
  },
);

export const updateAccountSelectedReducer = AppActions.updateAccountSelected.reducer<IState>(
  (state, action) => {
    const { accountUuid } = action.meta;
    if (action.error) {
      return {
        listAccounts: state.listAccounts.filter((account) => account.accountUuid != accountUuid),
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'can_not_get_account'),
        },
      };
    }
    return {
      ...initialState,
      profile: state.profile,
      listAccounts: state.listAccounts,
      accountSeleted: state.listAccounts.find((account) => account.accountUuid === accountUuid),
      geolocation: state.geolocation,
      providers: state.providers,
    };
  },
);

export const updateAccountReducer = AppActions.updateAccount.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_update_account'),
      },
    };
  }
  const { updateAccount } = action.payload;
  return {
    listAccounts: (state.listAccounts || []).map((account) =>
      account.accountUuid === updateAccount.accountUuid
        ? {
            ...account,
            ...updateAccount,
          }
        : account,
    ),
    accountSeleted:
      state.accountSeleted && state.accountSeleted.accountUuid === updateAccount.accountUuid
        ? {
            ...state.accountSeleted,
            ...updateAccount,
          }
        : state.accountSeleted,
    snackBarState: {
      open: true,
      type: 'success',
      message: 'update_account_success',
    },
  };
});

export const listProvidersReducer = NetworkActions.listProviders.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_list_providers'),
        },
      };
    }
    return {
      providers: action.payload.listProviders || [],
    };
  },
);

export const listNetworksReducer = NetworkActions.listNetworks.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_list_networks'),
      },
    };
  }
  const { accountUuid } = action.meta;
  const networks = action.payload.listNetworks;
  let selectedNetwork: INetwork | undefined = networks[0];

  if (
    state.profile &&
    state.profile.preference &&
    state.profile.preference.lastSelectedNetworkUuid
  ) {
    const { lastSelectedNetworkUuid } = state.profile.preference;
    const setting = lastSelectedNetworkUuid.find((info) => info.accountUuid === accountUuid);
    if (setting) {
      selectedNetwork = networks.find((network) => network.networkUuid === setting.networkUuid);
    }
  }

  return {
    networks: networks,
    networkSelected: selectedNetwork,
  };
});

export const getNetworkReducer = NetworkActions.getNetwork.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_get_network'),
      },
    };
  }

  const inputUuid = action.meta.networkUuid;
  const getNetwork = action.payload.getNetwork;

  const networks = (state.networks || []).concat();
  const selected = state.networkSelected;

  const retval: any = {};

  if (getNetwork) {
    if (selected && selected.networkUuid === inputUuid) {
      retval.networkSelected = {
        ...selected,
        ...getNetwork,
      };
    }

    const index = networks.findIndex((n) => n.networkUuid === inputUuid);

    if (index >= 0) {
      networks[index] = {
        ...networks[index],
        ...getNetwork,
      };
      retval.networks = networks;
    }
  } else {
    if (selected && selected.networkUuid === inputUuid) {
      retval.networkSelected = undefined;
    }
    if (networks.length > 0) {
      retval.networks = networks.filter((network) => network.networkUuid !== inputUuid);
    }
  }

  return retval;
});

export const getNetworkRoleReducer = NetworkActions.getNetworkRole.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_select_network_role'),
        },
      };
    }
    return {};
  },
);

export const selectNetworkReducer = NetworkActions.selectNetwork.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_get_network'),
        },
      };
    }
    return {
      networkSelected: action.payload,
    };
  },
);

export const createNetworkReducer = NetworkActions.createNetwork.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_create_network'),
        },
      };
    }
    const { status, data } = action.payload.createNetwork;

    if (status.length === 1 && status[0] === 'success' && data) {
      const networks = (state.networks || []).concat();
      networks.push(data);
      return { networks: networks };
    }
    return {};
  },
);

export const updateNetworkReducer = NetworkActions.updateNetwork.reducer<IState>(
  (state: IState, action) => {
    if (action.error) {
      // TODO: Handle error
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_update_network_information'),
        },
      };
    }
    const {
      networkUuid,
      networkName,
      networkDescription,
      defaultGasPrice,
      currencyName,
      currencySymbol,
    } = action.payload.updateNetwork;
    const { networks, networkSelected } = state;
    const updatedNetworks = (networks || []).map((network) => {
      if (network.networkUuid === networkUuid) {
        const newNetwork = {
          ...network,
          networkName,
          networkDescription,
        };
        if (
          newNetwork.blockchainInfo?.defaultGasPrice != undefined &&
          defaultGasPrice != undefined
        ) {
          newNetwork.blockchainInfo.defaultGasPrice = defaultGasPrice;
        }
        if (newNetwork.blockchainInfo?.currencyName && currencyName) {
          newNetwork.blockchainInfo.currencyName = currencyName;
        }
        if (newNetwork.blockchainInfo?.currencySymbol && currencySymbol) {
          newNetwork.blockchainInfo.currencySymbol = currencySymbol;
        }
        return newNetwork;
      }
      return network;
    });

    const updatedNetworkSelected =
      networkSelected &&
      updatedNetworks.find((network) => network.networkUuid === networkSelected.networkUuid);

    return {
      networks: updatedNetworks,
      networkSelected: updatedNetworkSelected,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'network-updated-successfully',
      },
    };
  },
);

export const destroyNetworkReducer = NetworkActions.destroyNetwork.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_delete_network'),
        },
      };
    }
    if (!action.payload.destroyNetwork) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: 'network_not_found_or_denied_to_delete',
        },
      };
    }
    // find target network
    const networks = (state.networks || []).concat();
    const { networkUuid } = action.meta.input;
    const network = networks.find((network) => network.networkUuid === networkUuid);
    if (!network) {
      return {};
    }
    // update status
    network.clusters.forEach((cluster) => {
      if (cluster.explorer) {
        cluster.explorer.serverInfo.status = 'removing';
      }
      cluster.status = 'removing';
      cluster.nodes.forEach((node) => {
        node.nodeInfo.status = 'removing';
        node.serverInfo.status = 'removing';
      });
    });
    return {
      networks: networks,
    };
  },
);

export const createClusterReducer = NetworkActions.createCluster.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_create_cluster'),
        },
      };
    }
    const { status, data } = action.payload.createCluster;

    if (status.length === 1 && status[0] === 'success' && data) {
      // find target network
      const networks = (state.networks || []).concat();
      const { networkUuid } = action.meta.input;
      const network = networks.find((network) => network.networkUuid === networkUuid);
      if (!network) {
        return {};
      }

      // check cluster already exists in list or not
      const newCluster = data;
      const pos = network.clusters.findIndex((cluster) => {
        return cluster.clusterUuid === newCluster.clusterUuid;
      });

      if (pos < 0) {
        network.clusters.push(newCluster);
      } else {
        network.clusters[pos] = newCluster;
      }

      return {
        networks: networks,
        snackBarState: {
          open: true,
          type: 'success',
          message: 'create_cluster_started',
        },
      };
    }
    return {};
  },
);

export const updateClusterReducer = NetworkActions.updateCluster.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_update_cluster'),
        },
      };
    }

    const networks = (state.networks || []).concat();
    const { networkUuid, clusterUuid } = action.meta.input;
    const network = networks.find((network) => network.networkUuid === networkUuid);

    if (network) {
      network.clusters = network.clusters.map((cluster) => {
        if (cluster.clusterUuid === clusterUuid) {
          cluster = action.payload.updateCluster;
        }
        return cluster;
      });
    }
    return {
      networks: networks,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'cluster-updated-successfully',
      },
    };
  },
);

export const deleteClusterReducer = NetworkActions.deleteCluster.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_delete_cluster'),
        },
      };
    }
    if (!action.payload.deleteCluster) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: 'cluster_not_found_or_not_empty',
        },
      };
    }

    const networks = (state.networks || []).concat();
    const { networkUuid, clusterUuid } = action.meta.input;
    const network = networks.find((network) => network.networkUuid === networkUuid);
    if (!network) {
      return {};
    }

    const cluster = network.clusters.find((cluster) => cluster.clusterUuid === clusterUuid);

    if (cluster) {
      if (cluster.explorer) {
        cluster.explorer.serverInfo.status = 'removing';
      }
      cluster.status = 'removing';
      cluster.nodes.forEach((node) => {
        node.nodeInfo.status = 'removing';
        node.serverInfo.status = 'removing';
      });
    }
    return {
      networks: networks,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'delete_cluster_started',
      },
    };
  },
);

export const createNodeReducer = NetworkActions.createNode.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_create_node'),
      },
    };
  }
  const { status, data } = action.payload.createNode;

  if (status.length === 1 && status[0] === 'success' && data) {
    const networks = (state.networks || []).concat();
    const networkUuid = action.meta.input.networkUuid;
    const clusterUuid = action.meta.input.clusterUuid;
    const network = networks.find((i) => i.networkUuid === networkUuid);

    if (!network) {
      return {};
    }
    const cluster = network.clusters.find((i) => i.clusterUuid === clusterUuid);

    if (!cluster) {
      return {};
    }
    const existsMap = cluster.nodes.reduce(
      (pre, cur, curIndex) => ({ ...pre, [cur.nodeUuid]: curIndex }),
      {},
    );
    data.forEach((node) => {
      if (existsMap[node.nodeUuid] === undefined) {
        cluster.nodes.push(node);
      } else {
        cluster.nodes[existsMap[node.nodeUuid]] = node;
      }
    });

    return {
      networks: networks,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'node-added-successfully',
      },
    };
  }
  return {};
});

export const updateNodeReducer = NetworkActions.updateNode.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_update_node'),
      },
    };
  }

  const networks = (state.networks || []).concat();
  const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
  const network = networks.find((i) => i.networkUuid === networkUuid);
  if (!network) {
    return {};
  }
  const cluster = network.clusters.find((i) => i.clusterUuid === clusterUuid);
  if (!cluster) {
    return {};
  }
  const index = cluster.nodes.findIndex((i) => i.nodeUuid === nodeUuid);
  if (index < 0) {
    return {};
  }
  cluster.nodes[index] = action.payload.updateNode;

  let selected = state.networkSelected;
  if (selected && selected.networkUuid === networkUuid) {
    selected = network;
  }

  return {
    networkSelected: selected,
    networks: networks,
    snackBarState: {
      open: true,
      type: 'success',
      message: 'node-updated-successfully',
    },
  };
});

export const deleteNodeReducer = NetworkActions.deleteNode.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_delete_node'),
      },
    };
  }
  if (!action.payload.deleteNode) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: 'node_not_found_or_validator',
      },
    };
  }

  const networks = (state.networks || []).concat();
  const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
  const network = networks.find((network) => network.networkUuid === networkUuid);
  if (!network) {
    return {};
  }

  const cluster = network.clusters.find((cluster) => cluster.clusterUuid === clusterUuid);
  if (!cluster) {
    return {};
  }

  const node = cluster.nodes.find((node) => node.nodeUuid === nodeUuid);
  if (node) {
    node.nodeInfo.status = 'removing';
    node.serverInfo.status = 'removing';
  }
  return {
    networks: networks,
    snackBarState: {
      open: true,
      type: 'success',
      message: 'delete_node_started',
    },
  };
});

export const controlNodeReducer = NetworkActions.controlNode.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_control_node'),
      },
    };
  }

  const { action: command, networkUuid, clusterUuid, nodeUuids } = action.meta.input;
  let display: string = 'control';

  switch (command) {
    case 'reboot':
    case 'start':
    case 'rebuild':
    case 'stop':
      display = command;
      break;
    case 'restartForce':
      display = 'reboot (force)';
      break;
  }

  let selected = state.networkSelected;
  const networks = (state.networks || []).concat();
  const index = networks.findIndex((n) => n.networkUuid === networkUuid);

  if (index >= 0) {
    const indexCluster = networks[index].clusters.findIndex((c) => c.clusterUuid === clusterUuid);

    if (indexCluster >= 0) {
      const nodes = networks[index].clusters[indexCluster].nodes.filter((n) =>
        nodeUuids.includes(n.nodeUuid),
      );

      for (const n of nodes) {
        n.serverInfo.status = 'pending';
        n.nodeInfo.status = 'pending';
      }

      if (selected && selected.networkUuid === networkUuid && nodes.length > 0) {
        selected = networks[index];
      }
    }
  }

  return {
    networks: networks,
    networkSelected: selected,
    snackBarState: {
      open: true,
      type: 'success',
      message: `${display}_command_sent`,
    },
  };
});

export const controlBlockExplorerReducer = NetworkActions.controlBlockExplorer.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_control_block_explorer'),
        },
      };
    }

    const { action: command, networkUuid, clusterUuid } = action.meta.input;
    let display: string = 'control';

    switch (command) {
      case 'reboot':
      case 'rebuild':
      case 'start':
      case 'stop':
      case 'restartForce':
        display = command;
        break;
    }

    let selected = state.networkSelected;
    const networks = (state.networks || []).concat();
    const index = networks.findIndex((n) => n.networkUuid === networkUuid);

    if (index >= 0) {
      const indexCluster = networks[index].clusters.findIndex((c) => c.clusterUuid === clusterUuid);

      if (indexCluster >= 0) {
        if (networks[index]?.clusters[indexCluster]?.explorer) {
          networks[index].clusters[indexCluster].explorer!.serverInfo.status = 'pending';
        }

        if (selected && selected.networkUuid === networkUuid) {
          selected = networks[index];
        }
      }
    }

    return {
      networks: networks,
      networkSelected: selected,
      snackBarState: {
        open: true,
        type: 'success',
        message: `${display}_command_sent`,
      },
    };
  },
);

export const listNetworkAccessesReducer = NetworkActions.listNetworkAccesses.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_list_network_accesses'),
        },
      };
    }

    return {
      networkAccesses: action.payload.listNetworkAccesses,
    };
  },
);

export const grantNetworkRoleReducer = NetworkActions.grantNetworkRole.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'network_role_can_not_grant'),
        },
      };
    }

    return {
      snackBarState: {
        open: true,
        type: 'success',
        message: 'grant_network_role_success',
      },
    };
  },
);

export const revokeNetworkRoleReducer = NetworkActions.revokeNetworkRole.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_revoke_network_role'),
        },
      };
    }

    return {
      snackBarState: {
        open: true,
        type: 'success',
        message: 'revoke_network_role_success',
      },
    };
  },
);

export const listAlertsReducer = AlertActions.listAlerts.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        type: 'error',
        open: true,
        message: getSnackbarMessage(state, action.payload, 'can_not_list_alerts'),
      },
    };
  }
  return {
    alertList: action.payload.listAlerts,
  };
});

export const listUnreadAlertsReducer = AlertActions.listUnreadAlerts.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'can_not_list_unread_alerts'),
        },
      };
    }
    return {
      unreadAlertList: action.payload.listAlerts,
    };
  },
);

export const setAlertReadReducer = AlertActions.setAlertRead.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        type: 'error',
        open: true,
        message: getSnackbarMessage(state, action.payload, 'can_not_set_alert_readed'),
      },
    };
  }
  const { alertUuid } = action.payload.setAlertRead;
  return {
    alertList: {
      ...state.alertList,
      alerts: (state.alertList.alerts || []).map((alert) =>
        alert.alertUuid === alertUuid ? action.payload.setAlertRead : alert,
      ),
    },
  };
});

export const setAllAlertsReadReducer = AlertActions.setAllAlertsRead.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'can_not_set_all_alert_readed'),
        },
      };
    }
    const { setAllAlertsRead } = action.payload;
    return {
      alertList: {
        ...state.alertList,
        alerts: setAllAlertsRead
          ? (state.alertList.alerts || []).map((alert) => ({ ...alert, read: true }))
          : state.alertList.alerts,
      },
      unreadAlertList: {
        pageInfo: { pageIndex: 0, totalItems: 0 },
        alerts: [],
      },
      newUnreadAlertList: {
        pageInfo: { pageIndex: 0, totalItems: 0 },
        alerts: [],
      },
    };
  },
);

// Only show sending invitation status on snackbar
export const sendInvitationReducer = NetworkActions.sendInvitation.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'can_not_send_invitation'),
        },
      };
    }
    return {
      snackBarState: {
        open: true,
        type: 'success',
        message: 'send_invitation_success',
      },
    };
  },
);

export const listInvitationsReducer = NetworkActions.listInvitations.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {};
    }
    return {
      invitationList: {
        ...action.payload.listInvitations,
        isSent: action.meta.isSent,
      },
    };
  },
);

export const cancelInvitationReducer = NetworkActions.cancelInvitation.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'can_not_cancel_invitation'),
        },
      };
    }
    const canceledInvitation = action.payload.cancelInvitation;
    return {
      invitationList: {
        ...state.invitationList,
        invitations: (state.invitationList.invitations || []).map((invitation) =>
          invitation.invitationUuid === canceledInvitation.invitationUuid
            ? {
                ...invitation,
                ...canceledInvitation,
              }
            : invitation,
        ),
      },
      snackBarState: {
        open: true,
        type: 'success',
        message: 'cancel_invitation_success',
      },
    };
  },
);

export const responseInvitationReducer = NetworkActions.responseInvitation.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'can_not_response_invitation'),
        },
      };
    }
    const responsedInvitation = action.payload.responseInvitation;
    return {
      invitationList: {
        ...state.invitationList,
        invitations: (state.invitationList.invitations || []).map((invitation) =>
          invitation.invitationUuid === responsedInvitation.invitationUuid
            ? {
                ...invitation,
                ...responsedInvitation,
              }
            : invitation,
        ),
      },
      snackBarState: {
        open: true,
        type: 'success',
        message: `${
          responsedInvitation.status === 'accepted' ? 'accept' : 'reject'
        }_invitation_success`,
      },
    };
  },
);

export const findClustersReducer = NetworkActions.findClusters.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_get_network_status'),
      },
    };
  }
  // must be clone array (not shallow copy)
  const networks = (state.networks || []).concat();

  // renew cluster info by reference
  const networkUuid = action.meta.networkUuid || '';
  const clusterUuids = action.meta.clusterUuids || [];
  const parent = networkUuid
    ? networks.find((network) => network.networkUuid === networkUuid)
    : undefined;

  // replace all
  if (parent) {
    if (
      clusterUuids.length < 1 ||
      (clusterUuids.length === parent.clusters.length &&
        parent.clusters.every((cluster) => clusterUuids.includes(cluster.clusterUuid)))
    ) {
      parent.clusters = action.payload.findClusters.map((c) => toICluster(c));
      return {
        networks: networks,
      };
    }
  }

  // upsert each network
  const clusterMap: any = {};

  networks.forEach((network, w) => {
    clusterMap[network.networkUuid] = {
      index: w,
      list: {},
    };
    network.clusters.forEach((cluster, c) => {
      clusterMap[network.networkUuid].list[cluster.clusterUuid] = {
        index: c,
      };
    });
  });
  const newComer: IClusterParent[] = [];
  const returnedUuids: string[] = [];

  // update
  action.payload.findClusters.forEach((cluster) => {
    if (!clusterMap[cluster.networkUuid]) {
      return;
    }
    if (clusterMap[cluster.networkUuid].list[cluster.clusterUuid]) {
      const keyW = clusterMap[cluster.networkUuid].index;
      const keyC = clusterMap[cluster.networkUuid].list[cluster.clusterUuid].index;
      networks[keyW].clusters[keyC] = toICluster(cluster);
    } else {
      // stock to insert
      newComer.push(cluster);
    }
    returnedUuids.push(cluster.clusterUuid);
  });

  // insert
  if (newComer.length > 0) {
    newComer.forEach((cluster) => {
      const keyW = clusterMap[cluster.networkUuid].index;
      networks[keyW].clusters.push(toICluster(cluster));
    });
  }
  if (clusterUuids.length < 1) {
    return {
      networks: networks,
    };
  }

  // delete removed cluster from network
  const removeList: string[] = [];
  clusterUuids.forEach((uuid) => {
    if (!returnedUuids.includes(uuid)) {
      removeList.push(uuid);
    }
  });
  if (removeList.length > 0) {
    if (parent) {
      parent.clusters = parent.clusters.filter(
        (cluster) => !removeList.includes(cluster.clusterUuid),
      );
    } else {
      networks.forEach((network) => {
        network.clusters = network.clusters.filter(
          (cluster) => !removeList.includes(cluster.clusterUuid),
        );
      });
    }
  }
  return {
    networks: networks,
  };
});

export const findNodesReducer = NetworkActions.findNodes.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_get_network_status'),
      },
    };
  }
  // must be clone array (not shallow copy)
  const networks = (state.networks || []).concat();

  // renew node info by reference
  const networkUuid = action.meta.networkUuid || '';
  const nodeUuids = action.meta.nodeUuids || [];
  const parent = networkUuid
    ? networks.find((network) => network.networkUuid === networkUuid)
    : undefined;

  // upsert each network
  const nodeMap: any = {};

  networks.forEach((network, w) => {
    nodeMap[network.networkUuid] = {
      index: w,
      list: {},
    };
    network.clusters.forEach((cluster, c) => {
      nodeMap[network.networkUuid].list[cluster.clusterUuid] = {
        index: c,
        list: {},
      };
      cluster.nodes.forEach((node, n) => {
        nodeMap[network.networkUuid].list[cluster.clusterUuid].list[node.nodeUuid] = {
          index: n,
        };
      });
    });
  });
  const newComer: INodeParent[] = [];
  const returnedUuids: string[] = [];

  // update
  action.payload.findNodes.forEach((node) => {
    if (!nodeMap[node.networkUuid] || !nodeMap[node.networkUuid].list[node.clusterUuid]) {
      return;
    }
    if (nodeMap[node.networkUuid].list[node.clusterUuid].list[node.nodeUuid]) {
      const keyW = nodeMap[node.networkUuid].index;
      const keyC = nodeMap[node.networkUuid].list[node.clusterUuid].index;
      const keyN = nodeMap[node.networkUuid].list[node.clusterUuid].list[node.nodeUuid].index;
      networks[keyW].clusters[keyC].nodes[keyN] = toINode(node);
    } else {
      // stock to insert
      newComer.push(node);
    }
    returnedUuids.push(node.nodeUuid);
  });

  // insert
  if (newComer.length > 0) {
    newComer.forEach((node) => {
      const keyW = nodeMap[node.networkUuid].index;
      const keyC = nodeMap[node.networkUuid].list[node.clusterUuid].index;
      networks[keyW].clusters[keyC].nodes.push(toINode(node));
    });
  }
  if (nodeUuids.length < 1) {
    return {
      networks: networks,
    };
  }

  // delete removed node from cluster
  const removeList: string[] = [];

  nodeUuids.forEach((uuid) => {
    if (!returnedUuids.includes(uuid)) {
      removeList.push(uuid);
    }
  });

  if (removeList.length > 0) {
    if (parent) {
      parent.clusters.forEach((cluster) => {
        cluster.nodes = cluster.nodes.filter((node) => !removeList.includes(node.nodeUuid));
      });
    } else {
      networks.forEach((network) => {
        network.clusters.forEach((cluster) => {
          cluster.nodes = cluster.nodes.filter((node) => !removeList.includes(node.nodeUuid));
        });
      });
    }
  }
  return {
    networks: networks,
  };
});

export const createAccountReducer = AppActions.createAccount.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_create_account'),
      },
    };
  }
  const newAccount = action.payload.createAccount;
  const accounts = [...state.listAccounts, newAccount];
  return {
    listAccounts: accounts,
    snackBarState: {
      open: true,
      type: 'success',
      message: 'create_account_success',
    },
  };
});

export const removeAccountReducer = AppActions.removeAccount.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_remove_account'),
      },
    };
  }
  const { removeAccount } = action.payload;

  if (removeAccount.status === 'success') {
    const { accountUuid } = action.meta;
    const accounts = [...state.listAccounts].filter((acc) => acc.accountUuid !== accountUuid);
    const result: any = {
      listAccounts: accounts,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'remove_account_success',
      },
    };

    if (state.accountSeleted && state.accountSeleted.accountUuid === accountUuid) {
      result.accountSeleted = accounts[0] || void 0;
    }

    return result;
  } else {
    const errors = removeAccount.errors || [];

    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: errors && errors.length > 0 ? errors[0] : 'remove_account_success',
      },
    };
  }
});

export const listActiveLicensesReducer = PaymentAction.listActiveLicenses.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_list_active_licenses'),
        },
      };
    }
    return {
      activeLicenses: action.payload.listActiveLicenses || [],
    };
  },
);

export const listActiveLicensesSummaryReducer =
  PaymentAction.listActiveLicensesSummary.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(
            state,
            action.payload,
            'can_not_list_active_licenses_summary',
          ),
        },
      };
    }
    return {
      activeLicensesSummary: action.payload.listActiveLicensesSummary,
    };
  });

export const listAvailableLicensesReducer = PaymentAction.listAvailableLicenses.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_list_available_licenses'),
        },
      };
    }
    return {};
  },
);

export const listInsufficientLicensesReducer =
  PaymentAction.listInsufficientLicenses.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_list_insufficient_licenses'),
        },
      };
    }
    return {};
  });

export const listInsufficientClusterLicensesReducer =
  PaymentAction.listInsufficientClusterLicenses.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(
            state,
            action.payload,
            'can_not_list_insufficient_cluster_licenses',
          ),
        },
      };
    }
    return {};
  });

export const listInsufficientNodeLicensesReducer =
  PaymentAction.listInsufficientNodeLicenses.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(
            state,
            action.payload,
            'can_not_list_insufficient_node_licenses',
          ),
        },
      };
    }
    return {};
  });

export const listInsufficientNodeServerLicensesReducer =
  PaymentAction.listInsufficientNodeServerLicenses.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(
            state,
            action.payload,
            'can_not_list_insufficient_node_server_licenses',
          ),
        },
      };
    }
    return {};
  });

export const listInsufficientVolumeLicensesReducer =
  PaymentAction.listInsufficientVolumeLicenses.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(
            state,
            action.payload,
            'can_not_list_insufficient_volume_licenses',
          ),
        },
      };
    }
    return {};
  });

export const estimateLicenseFeeReducer = PaymentAction.estimateLicenseFee.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_estimate_licenses'),
        },
      };
    }
    return {};
  },
);

export const listBillingsReducer = PaymentAction.listBillings.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_list_billings'),
      },
    };
  }
  return {
    billings: action.payload.listBillings,
  };
});

export const listCouponsReducer = PaymentAction.listCoupons.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_list_coupons'),
      },
    };
  }
  return {
    coupons: action.payload.listCoupons,
  };
});

export const listCardsReducer = PaymentAction.listCards.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_list_cards'),
      },
    };
  }
  return {
    cardSummary: action.payload.listCards,
  };
});

export const registerCardReducer = PaymentAction.registerCard.reducer<IState>((state, action) => {
  let snack: ISnackBarState = {
    open: true,
    type: 'error',
    message: '',
  };

  if (action.error) {
    snack.message = getSnackbarMessage(state, action.payload, 'card_registration_failed');
  } else if (action.payload.registerCard.status) {
    snack = {
      ...snack,
      type: 'success',
      message: 'card_registration_successfully',
    };
  } else {
    snack.message = action.payload.registerCard.error || 'card_registration_failed';
  }

  return {
    snackBarState: snack,
  };
});

export const purchaseLicensesReducer = PaymentAction.purchaseLicenses.reducer<IState>(
  (state, action) => {
    let snack: ISnackBarState = {
      open: true,
      type: 'error',
      message: '',
    };

    if (action.error) {
      snack.message = getSnackbarMessage(state, action.payload, 'unknown_payment_error');
    } else if (action.payload.purchaseLicenses.status) {
      snack = {
        ...snack,
        type: 'success',
        message: 'licenses_purchased_successfully',
      };
    } else {
      snack.message = action.payload.purchaseLicenses.error.message || 'unknown_payment_error';
    }

    return {
      snackBarState: snack,
    };
  },
);

export const cancelPurchaseLicenseReducer = PaymentAction.cancelPurchaseLicense.reducer<IState>(
  (state, action) => {
    let snack: ISnackBarState = {
      open: true,
      type: 'error',
      message: '',
    };

    if (action.error) {
      snack.message = getSnackbarMessage(
        state,
        action.payload,
        'failed_to_cancel_licenses_purchase',
      );
    } else if (action.payload.cancelPurchaseLicense.status) {
      snack = {
        ...snack,
        type: 'success',
        message: 'licenses_purchased_canceled_successfully',
      };
    } else {
      snack.message =
        action.payload.cancelPurchaseLicense.error || 'failed_to_cancel_licenses_purchase';
    }

    return {
      snackBarState: snack,
    };
  },
);

export const listEndpointRestrictionsReducer =
  NetworkActions.listEndpointRestrictions.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_list_restrictions'),
        },
      };
    }

    const { networkUuid, clusterUuid } = action.meta;
    const restrictions = (state.restrictions || []).concat();
    const indexNetwork = restrictions.findIndex((r) => r.networkUuid === networkUuid);
    const newRules = action.payload.listEndpointRestrictions;

    if (indexNetwork < 0) {
      restrictions.push({
        networkUuid: networkUuid,
        clusters: [
          {
            clusterUuid: clusterUuid,
            rules: newRules,
            nodes: [],
            explorer: {
              rules: [],
            },
          },
        ],
      });
    } else {
      const indexCluster = restrictions[indexNetwork].clusters.findIndex(
        (r) => r.clusterUuid === clusterUuid,
      );

      if (indexCluster < 0) {
        restrictions[indexNetwork].clusters.push({
          clusterUuid: clusterUuid,
          rules: newRules,
          nodes: [],
          explorer: {
            rules: [],
          },
        });
      } else {
        restrictions[indexNetwork].clusters[indexCluster].rules = newRules;
      }
    }

    return {
      restrictions: restrictions,
    };
  });

export const listExplorerRestrictionsReducer =
  NetworkActions.listExplorerRestrictions.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_list_restrictions'),
        },
      };
    }

    const { networkUuid, clusterUuid } = action.meta;
    const restrictions = (state.restrictions || []).concat();
    const indexNetwork = restrictions.findIndex((r) => r.networkUuid === networkUuid);
    const newRules = action.payload.listExplorerRestrictions;

    if (indexNetwork < 0) {
      restrictions.push({
        networkUuid: networkUuid,
        clusters: [
          {
            clusterUuid: clusterUuid,
            nodes: [],
            explorer: {
              rules: newRules,
            },
          },
        ],
      });
    } else {
      const indexCluster = restrictions[indexNetwork].clusters.findIndex(
        (r) => r.clusterUuid === clusterUuid,
      );

      if (indexCluster < 0) {
        restrictions[indexNetwork].clusters.push({
          clusterUuid: clusterUuid,
          nodes: [],
          explorer: {
            rules: newRules,
          },
        });
      } else {
        restrictions[indexNetwork].clusters[indexCluster].explorer.rules = newRules;
      }
    }

    return {
      restrictions: restrictions,
    };
  });

export const listNodeRestrictionsReducer = NetworkActions.listNodeRestrictions.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_list_restrictions'),
        },
      };
    }

    const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
    const restrictions = (state.restrictions || []).concat();
    const indexNetwork = restrictions.findIndex((r) => r.networkUuid === networkUuid);
    const newRules = action.payload.listNodeRestrictions;

    if (indexNetwork < 0) {
      restrictions.push({
        networkUuid: networkUuid,
        clusters: [
          {
            clusterUuid: clusterUuid,
            nodes: [
              {
                nodeUuid: nodeUuid,
                rules: newRules,
              },
            ],
            explorer: {
              rules: [],
            },
          },
        ],
      });
    } else {
      const indexCluster = restrictions[indexNetwork].clusters.findIndex(
        (r) => r.clusterUuid === clusterUuid,
      );

      if (indexCluster < 0) {
        restrictions[indexNetwork].clusters.push({
          clusterUuid: clusterUuid,
          nodes: [
            {
              nodeUuid: nodeUuid,
              rules: newRules,
            },
          ],
          explorer: {
            rules: [],
          },
        });
      } else {
        const indexNode = restrictions[indexNetwork].clusters[indexCluster].nodes.findIndex(
          (r) => r.nodeUuid === nodeUuid,
        );

        if (indexNode < 0) {
          restrictions[indexNetwork].clusters[indexCluster].nodes.push({
            nodeUuid: nodeUuid,
            rules: newRules,
          });
        } else {
          restrictions[indexNetwork].clusters[indexCluster].nodes[indexNode].rules = newRules;
        }
      }
    }

    return {
      restrictions: restrictions,
    };
  },
);

export const setEndpointRestrictionReducer = NetworkActions.setEndpointRestriction.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_set_restrictions'),
        },
      };
    }

    const { networkUuid, clusterUuid } = action.meta.input;
    const restrictions = (state.restrictions || []).concat();
    const indexNetwork = restrictions.findIndex((r) => r.networkUuid === networkUuid);
    const newRules = action.payload.setEndpointRestriction;

    if (indexNetwork < 0) {
      restrictions.push({
        networkUuid: networkUuid,
        clusters: [
          {
            clusterUuid: clusterUuid,
            rules: newRules,
            nodes: [],
            explorer: {
              rules: [],
            },
          },
        ],
      });
    } else {
      const indexCluster = restrictions[indexNetwork].clusters.findIndex(
        (r) => r.clusterUuid === clusterUuid,
      );

      if (indexCluster < 0) {
        restrictions[indexNetwork].clusters.push({
          clusterUuid: clusterUuid,
          rules: newRules,
          nodes: [],
          explorer: {
            rules: [],
          },
        });
      } else {
        restrictions[indexNetwork].clusters[indexCluster].rules = newRules;
      }
    }

    return {
      restrictions: restrictions,
      snackBarState: {
        type: 'success',
        open: true,
        message: 'update_restriction_successfully',
      },
    };
  },
);

export const setExplorerRestrictionReducer = NetworkActions.setExplorerRestriction.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_set_restrictions'),
        },
      };
    }

    const { networkUuid, clusterUuid } = action.meta.input;
    const restrictions = (state.restrictions || []).concat();
    const indexNetwork = restrictions.findIndex((r) => r.networkUuid === networkUuid);
    const newRules = action.payload.setExplorerRestriction;

    if (indexNetwork < 0) {
      restrictions.push({
        networkUuid: networkUuid,
        clusters: [
          {
            clusterUuid: clusterUuid,
            nodes: [],
            explorer: {
              rules: newRules,
            },
          },
        ],
      });
    } else {
      const indexCluster = restrictions[indexNetwork].clusters.findIndex(
        (r) => r.clusterUuid === clusterUuid,
      );

      if (indexCluster < 0) {
        restrictions[indexNetwork].clusters.push({
          clusterUuid: clusterUuid,
          nodes: [],
          explorer: {
            rules: newRules,
          },
        });
      } else {
        restrictions[indexNetwork].clusters[indexCluster].explorer.rules = newRules;
      }
    }

    return {
      restrictions: restrictions,
      snackBarState: {
        type: 'success',
        open: true,
        message: 'update_restriction_successfully',
      },
    };
  },
);

export const setNodeRestrictionReducer = NetworkActions.setNodeRestriction.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_set_restrictions'),
        },
      };
    }

    const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
    const restrictions = (state.restrictions || []).concat();
    const indexNetwork = restrictions.findIndex((r) => r.networkUuid === networkUuid);
    const newRules = action.payload.setNodeRestriction;

    if (indexNetwork < 0) {
      restrictions.push({
        networkUuid: networkUuid,
        clusters: [
          {
            clusterUuid: clusterUuid,
            nodes: [
              {
                nodeUuid: nodeUuid,
                rules: newRules,
              },
            ],
            explorer: {
              rules: [],
            },
          },
        ],
      });
    } else {
      const indexCluster = restrictions[indexNetwork].clusters.findIndex(
        (r) => r.clusterUuid === clusterUuid,
      );

      if (indexCluster < 0) {
        restrictions[indexNetwork].clusters.push({
          clusterUuid: clusterUuid,
          nodes: [
            {
              nodeUuid: nodeUuid,
              rules: newRules,
            },
          ],
          explorer: {
            rules: [],
          },
        });
      } else {
        const indexNode = restrictions[indexNetwork].clusters[indexCluster].nodes.findIndex(
          (r) => r.nodeUuid === nodeUuid,
        );

        if (indexNode < 0) {
          restrictions[indexNetwork].clusters[indexCluster].nodes.push({
            nodeUuid: nodeUuid,
            rules: newRules,
          });
        } else {
          restrictions[indexNetwork].clusters[indexCluster].nodes[indexNode].rules = newRules;
        }
      }
    }

    return {
      restrictions: restrictions,
      snackBarState: {
        type: 'success',
        open: true,
        message: 'update_restriction_successfully',
      },
    };
  },
);

export const expandNodeVolumeReducer = NetworkActions.expandNodeVolume.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_expand_node_volume_size'),
        },
      };
    }
    const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
    const { status, data } = action.payload.expandNodeVolume;

    if (status.length === 1 && status[0] === 'success' && data) {
      let selected = state.networkSelected;
      const networks = (state.networks || []).concat();
      const index = networks.findIndex((n) => n.networkUuid === networkUuid);

      if (index >= 0) {
        const indexCluster = networks[index].clusters.findIndex(
          (c) => c.clusterUuid === clusterUuid,
        );

        if (indexCluster >= 0) {
          const nodes = networks[index].clusters[indexCluster].nodes.filter(
            (n) => n.nodeUuid === nodeUuid,
          );
          nodes.forEach((n) => {
            n.serverInfo.status = 'pending';
            n.nodeInfo.status = 'pending';
          });
          if (selected && selected.networkUuid === networkUuid && nodes.length > 0) {
            selected = networks[index];
          }
        }
      }

      return {
        networks: networks,
        networkSelected: selected,
        snackBarState: {
          open: true,
          type: 'success',
          message: 'expanding_volume_size_started',
        },
      };
    }

    return data || status.includes('needRegister') || status.includes('needCharge')
      ? {}
      : {
          snackBarState: {
            open: true,
            type: 'error',
            message: 'failed_to_expand_node_volume_size',
          },
        };
  },
);

export const expandBlockExplorerVolumeReducer =
  NetworkActions.expandBlockExplorerVolume.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(
            state,
            action.payload,
            'can_not_expand_block_explorer_volume_size',
          ),
        },
      };
    }
    const { networkUuid, clusterUuid } = action.meta.input;
    const { status, data } = action.payload.expandBlockExplorerVolume;

    if (status.length === 1 && status[0] === 'success' && data) {
      let selected = state.networkSelected;
      const networks = (state.networks || []).concat();
      const index = networks.findIndex((n) => n.networkUuid === networkUuid);

      if (index >= 0) {
        const indexCluster = networks[index].clusters.findIndex(
          (c) => c.clusterUuid === clusterUuid,
        );

        if (indexCluster >= 0) {
          if (networks[index]?.clusters[indexCluster]?.explorer) {
            networks[index].clusters[indexCluster].explorer!.serverInfo.status = 'pending';
          }

          if (selected && selected.networkUuid === networkUuid) {
            selected = networks[index];
          }
        }
      }

      return {
        networks: networks,
        networkSelected: selected,
        snackBarState: {
          open: true,
          type: 'success',
          message: 'expanding_volume_size_started',
        },
      };
    }

    return data || status.includes('needRegister') || status.includes('needCharge')
      ? {}
      : {
          snackBarState: {
            open: true,
            type: 'error',
            message: 'failed_to_expand_node_volume_size',
          },
        };
  });

export const getNodeMetricsReducer = NetworkActions.getNodeMetrics.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_get_node_metrics'),
        },
      };
    }
    return {};
  },
);

export const getNodeLogReducer = NetworkActions.getNodeLog.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_get_node_log'),
      },
    };
  }
  return {};
});

export const getExplorerMetricsReducer = NetworkActions.getExplorerMetrics.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_get_block_explorer_metrics'),
        },
      };
    }
    return {};
  },
);

export const getExplorerLogReducer = NetworkActions.getExplorerLog.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_get_block_explorer_log'),
        },
      };
    }
    return {};
  },
);

export const sendAllEtherFromNodeReducer = NetworkActions.sendAllEtherFromNode.reducer<IState>(
  (state, action) => {
    const networks = (state.networks || []).concat();
    const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
    const node = networks
      .find((network) => network.networkUuid === networkUuid)
      ?.clusters.find((cluster) => cluster.clusterUuid === clusterUuid)
      ?.nodes.find((node) => node.nodeUuid === nodeUuid);
    if (action.error) {
      if (node?.signerInfo?.externalClef) {
        return {
          snackBarState: {
            open: true,
            type: 'error',
            message: 'can_not_send_token_from_external_node',
          },
        };
      }
      const customMsg = action.payload?.message.includes('request denied') ? 'Request denied' : '';
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message:
            customMsg || getSnackbarMessage(state, action.payload, 'Can not process transaction'),
        },
      };
    }

    const network = action.payload.sendAllEtherFromNode;
    const retval: { networks?: INetwork[]; networkSelected?: INetwork } = {};

    const inputUuid = action.meta.input.networkUuid;
    const selected = state.networkSelected;

    if (selected && selected.networkUuid === inputUuid) {
      retval.networkSelected = network;
    }
    const index = networks.findIndex((n) => n.networkUuid === inputUuid);

    if (index >= 0) {
      networks[index] = network;
      retval.networks = networks;
    }
    return retval;
  },
);

export const estimateTransferGasReducer = NetworkActions.estimateTransferGas.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'Can not estimate transaction gas'),
        },
      };
    }
    return {};
  },
);

export const registerAddressReducer = AppActions.registerAddress.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_register_account_address'),
        },
      };
    }

    const { registerAddress } = action.payload;
    const { listAccounts, accountSeleted } = state;
    return {
      listAccounts: (listAccounts || []).map((ac) =>
        ac.accountUuid !== registerAddress.accountUuid ? ac : registerAddress,
      ),
      accountSeleted:
        accountSeleted && accountSeleted.accountUuid === registerAddress.accountUuid
          ? {
              ...accountSeleted,
              ...registerAddress,
            }
          : accountSeleted,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'update_account_address_success',
      },
    };
  },
);

export const updateContactReducer = AppActions.updateContact.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_update_account_contact'),
      },
    };
  }

  const { updateContact } = action.payload;
  return {
    listAccounts: (state.listAccounts || []).map((account) =>
      account.accountUuid === updateContact.accountUuid
        ? {
            ...account,
            ...updateContact,
          }
        : account,
    ),
    accountSeleted:
      state.accountSeleted && state.accountSeleted.accountUuid === updateContact.accountUuid
        ? {
            ...state.accountSeleted,
            ...updateContact,
          }
        : state.accountSeleted,
    snackBarState: {
      open: true,
      type: 'success',
      message: 'update_account_contact_success',
    },
  };
});

export const checkClusterRemovalStatusReducer =
  NetworkActions.checkClusterRemovalStatus.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_get_cluster_removal_status'),
        },
      };
    }
    return {};
  });

export const checkNodeRemovalStatusReducer = NetworkActions.checkNodeRemovalStatus.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_get_node_removal_status'),
        },
      };
    }
    return {};
  },
);

export const getTxpoolStatusReducer = NetworkActions.getTxpoolStatus.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'an_error_occured'),
        },
      };
    }
    return {};
  },
);

export const getGeolocationReducer = AppActions.getGeolocation.reducer<IState>((state, action) => {
  if (action.error) {
    // Geolocation information is optional, so we don't need to handle this error
    return {};
  }

  return {
    geolocation: action.payload.getGeolocation,
  };
});

// export const listConsortiumsReducer = NetworkActions.listConsortiums.reducer<IState>((state, action) => {
//   if (action.error) {
//     return {
//       snackBarState: {
//         open: true,
//         type: 'error',
//         message: getSnackbarMessage(state, action.payload, 'cannot_list_consortiums'),
//       },
//     };
//   }

//   return {
//     consortiums: action.payload.listConsortiums,
//   };
// });

export const updateConsortiumRoleReducer = NetworkActions.updateConsortiumRole.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'cannot_update_consortium_role'),
        },
      };
    }

    const updatedConsortium = action.payload.updateConsortiumRole;
    const { networkUuid } = action.meta.input;

    return {
      networks:
        state.networks &&
        state.networks.map((network) =>
          network.networkUuid === networkUuid
            ? {
                ...network,
                consortiums: network.consortiums.map((consortium) =>
                  consortium.accountUuid === updatedConsortium.accountUuid
                    ? updatedConsortium
                    : consortium,
                ),
              }
            : network,
        ),
      networkSelected: state.networkSelected && {
        ...state.networkSelected,
        consortiums: state.networkSelected.consortiums.map((consortium) =>
          consortium.accountUuid === updatedConsortium.accountUuid ? updatedConsortium : consortium,
        ),
      },
      snackBarState: {
        open: true,
        type: 'success',
        message: 'update_consortium_role_success',
      },
    };
  },
);

export const withdrawConsortiumReducer = NetworkActions.withdrawConsortium.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'cannot_withdrawal_consortium'),
        },
      };
    }

    const { targetAccountUuid, networkUuid } = action.meta.input;

    return {
      networks:
        state.networks &&
        state.networks.map((network) =>
          network.networkUuid === networkUuid
            ? {
                ...network,
                consortiums: network.consortiums.filter(
                  (consortium) => consortium.accountUuid !== targetAccountUuid,
                ),
              }
            : network,
        ),
      networkSelected: state.networkSelected && {
        ...state.networkSelected,
        consortiums: state.networkSelected.consortiums.filter(
          (consortium) => consortium.accountUuid !== targetAccountUuid,
        ),
      },
      snackBarState: {
        open: true,
        type: 'success',
        message: 'withdraw_consortium_success',
      },
    };
  },
);

export const updateNetworkOptionReducer = NetworkActions.updateNetworkOption.reducer<IState>(
  (state: IState, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_update_network_option'),
        },
      };
    }
    const { networkUuid, options } = action.payload.updateNetworkOption;
    const { networks, networkSelected } = state;
    const updatedNetworks = (networks || []).map((network) =>
      network.networkUuid === networkUuid
        ? {
            ...network,
            options,
          }
        : network,
    );
    const updatedNetworkSelected =
      networkSelected &&
      updatedNetworks.find((network) => network.networkUuid === networkSelected.networkUuid);

    return {
      networks: updatedNetworks,
      networkSelected: updatedNetworkSelected,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'update_network_option_success',
      },
    };
  },
);

export const listCustomDomainsReducer = NetworkActions.listCustomDomains.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_list_providers'),
        },
      };
    }
    return {
      customDomains: action.payload.listCustomDomains || [],
    };
  },
);

export const addCustomDomainReducer = NetworkActions.addCustomDomain.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_add_custom_domain'),
        },
      };
    }
    if (!action.payload.addCustomDomain) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: 'cluster_not_found_or_not_empty',
        },
      };
    }

    const networks = (state.networks || []).concat();
    const { networkUuid, clusterUuid } = action.meta.input;
    const network = networks.find((network) => network.networkUuid === networkUuid);

    return {
      customDomain: action.payload.addCustomDomain,
      networks: networks,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'custom-domain-added-successfully',
      },
    };
  },
);

export const removeCustomDomainReducer = NetworkActions.removeCustomDomain.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_remove_custom_domain'),
        },
      };
    }
    if (!action.payload.removeCustomDomain) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: 'cluster_not_found_or_not_empty',
        },
      };
    }

    const networks = (state.networks || []).concat();
    const { networkUuid, clusterUuid } = action.meta.input;
    const network = networks.find((network) => network.networkUuid === networkUuid);

    return {
      networks: networks,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'custom-domain-removed-successfully',
      },
    };
  },
);

export const checkCustomDomainStatusReducer =
  NetworkActions.checkCustomDomainStatus.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'an_error_occured'),
        },
      };
    }

    const networks = (state.networks || []).concat();
    const { networkUuid, clusterUuid } = action.meta.input;
    const network = networks.find((network) => network.networkUuid === networkUuid);

    return {
      customDomain: undefined,
      networks: networks,
    };
  });

export const createBlockExplorerReducer = NetworkActions.createBlockExplorerAction.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_add_block_explorer'),
        },
      };
    }
    const { status, data } = action.payload.createBlockExplorer;

    if (status.length === 1 && status[0] === 'success' && data) {
      const networks = (state.networks || []).concat();
      const networkUuid = action.meta.input.networkUuid;
      const clusterUuid = action.meta.input.clusterUuid;
      const network = networks.find((i) => i.networkUuid === networkUuid);

      if (!network) {
        return {};
      }
      const cluster = network.clusters.find((i) => i.clusterUuid === clusterUuid);

      if (!cluster) {
        return {};
      }

      cluster.explorer = action.payload.createBlockExplorer.data;

      return {
        networks,
      };
    }
    return {};
  },
);

export const deleteBlockExplorerReducer = NetworkActions.deleteBlockExplorerAction.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_remove_block_explorer'),
        },
      };
    }
    if (!action.payload.deleteBlockExplorer) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: 'block_explorer_not_found_or_not_empty',
        },
      };
    }
    const networks = (state.networks || []).concat();
    const { networkUuid, clusterUuid } = action.meta.input;
    const network = networks.find((network) => network.networkUuid === networkUuid);
    if (!network) {
      return {};
    }

    const cluster = network.clusters.find((cluster) => cluster.clusterUuid === clusterUuid);
    if (!cluster) {
      return {};
    }
    if (cluster.explorer) {
      cluster.explorer.serverInfo.status = 'removing';
    }

    return {
      networks: networks,
    };
  },
);

export const acceptTermsOfServiceReducer = AppActions.acceptTermsOfService.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'unknow_error_occured'),
        },
      };
    }

    const profile = action.payload.acceptTermsOfService;
    let accountSeleted = profile.accounts.find(
      (account) => account.accountUuid === profile.preference.lastSelectedAccountUuid,
    );

    if (!accountSeleted && profile.accounts.length) {
      accountSeleted = profile.accounts[0];
    }

    return {
      profile: action.payload.acceptTermsOfService,
      accountSeleted,
      listAccounts: profile.accounts,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'create_user_successfully',
      },
    };
  },
);

export const updateNodeVersionReducer = NetworkActions.updateNodeVersion.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_update_node_version'),
        },
      };
    }
    if (!action.payload.updateNodeVersion) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: 'update_node_version_failed',
        },
      };
    }

    const networks = (state.networks || []).concat();
    const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
    const network = networks.find((network) => network.networkUuid === networkUuid);
    if (!network) {
      return {};
    }

    const cluster = network.clusters.find((cluster) => cluster.clusterUuid === clusterUuid);
    if (!cluster) {
      return {};
    }

    const node = cluster.nodes.find((node) => node.nodeUuid === nodeUuid);
    if (node) {
      node.nodeInfo.status = 'pending';
      node.serverInfo.status = 'pending';
    }
    return {
      networks,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'update_node_version_started',
      },
    };
  },
);

export const updateBlockExplorerReducer = NetworkActions.updateBlockExplorer.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_update_block_exp'),
        },
      };
    }

    const { networkUuid, clusterUuid } = action.meta.input;

    let selected = state.networkSelected;
    const networks = (state.networks || []).concat();
    const index = networks.findIndex((n) => n.networkUuid === networkUuid);

    if (index >= 0) {
      const indexCluster = networks[index].clusters.findIndex((c) => c.clusterUuid === clusterUuid);

      if (indexCluster >= 0) {
        if (networks[index]?.clusters[indexCluster]?.explorer) {
          networks[index].clusters[indexCluster].explorer!.serverInfo.status = 'pending';
        }

        if (selected && selected.networkUuid === networkUuid) {
          selected = networks[index];
        }
      }
    }

    return {
      networks: networks,
      networkSelected: selected,
      snackBarState: {
        open: true,
        type: 'success',
        message: `update_block_exp_started`,
      },
    };
  },
);

export const updateNodeExplorerVersionReducer =
  NetworkActions.updateNodeExplorerVersion.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_update_node_version'),
        },
      };
    }
    if (!action.payload.updateNodeExplorerVersion) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: 'update_node_version_failed',
        },
      };
    }

    const networks = (state.networks || []).concat();
    const { networkUuid, clusterUuid } = action.meta.input;
    const network = networks.find((network) => network.networkUuid === networkUuid);
    if (!network) {
      return {};
    }

    const cluster = network.clusters.find((cluster) => cluster.clusterUuid === clusterUuid);
    if (!cluster) {
      return {};
    }

    if (cluster.explorer) {
      cluster.explorer.serverInfo.status = 'pending';
    }
    return {
      networks,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'update_node_version_started',
      },
    };
  });

const scheduleHardForkReducer = NetworkActions.createHardForkProposal.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_hard_fork'),
        },
      };
    }
    if (!action.payload.createHardForkProposal) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: 'schedule_hard_fork_failed',
        },
      };
    }

    return {
      snackBarState: {
        open: true,
        type: 'success',
        message: 'create_hard_fork_proposal_successfully',
      },
    };
  },
);

export const voteHardForkScheduleReducer = NetworkActions.voteHardForkProposal.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_vote_proposal'),
        },
      };
    }

    // find target network
    const networks = (state.networks || []).concat();
    const { networkUuid, uuid } = action.meta.input;
    const network = networks.find((network) => network.networkUuid === networkUuid);
    if (!network) {
      return {};
    }
    if (action.payload.voteHardForkProposal.status === 'approved') {
      network.planningHardFork = {
        startAt: new Date().toISOString(),
        proposalId: uuid,
      };
    }

    return {
      networks,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'vote_proposal_success',
      },
    };
  },
);

export const listHardForkProposalReducer = NetworkActions.listHardForkProposals.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_list_proposals'),
        },
      };
    }
    return {
      hardForkProposals: action.payload.listHardForkProposals ?? [],
    };
  },
);

export const updateLatestHardForkReducer = NetworkActions.applyHardForkToNode.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_update_latest_hard_fork'),
        },
      };
    }

    const networks = (state.networks || []).concat();
    const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
    const network = networks.find((network) => network.networkUuid === networkUuid);
    if (!network) {
      return {};
    }

    const cluster = network.clusters.find((cluster) => cluster.clusterUuid === clusterUuid);
    if (!cluster) {
      return {};
    }

    const node = cluster.nodes.find((node) => node.nodeUuid === nodeUuid);
    if (node) {
      node.nodeInfo.status = 'pending';
      node.serverInfo.status = 'pending';
    }
    return {
      networks,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'update_latest_hard_fork_start',
      },
    };
  },
);

export const updateExplorerToLatestHardForkReducer =
  NetworkActions.applyHardForkToBlockExplorer.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_update_latest_hard_fork'),
        },
      };
    }

    const { networkUuid, clusterUuid } = action.meta.input;

    let selected = state.networkSelected;
    const networks = (state.networks || []).concat();
    const index = networks.findIndex((n) => n.networkUuid === networkUuid);

    if (index >= 0) {
      const indexCluster = networks[index].clusters.findIndex((c) => c.clusterUuid === clusterUuid);

      if (indexCluster >= 0) {
        if (networks[index]?.clusters[indexCluster]?.explorer) {
          networks[index].clusters[indexCluster].explorer!.serverInfo.status = 'pending';
        }

        if (selected && selected.networkUuid === networkUuid) {
          selected = networks[index];
        }
      }
    }

    return {
      networks,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'update_latest_hard_fork_start',
      },
    };
  });

export const retryApplyHardForkToNetworkReducer =
  NetworkActions.retryApplyHardForkToNetwork.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'retry_apply_hard_fork_fail'),
        },
      };
    }

    // find target network
    const networks = (state.networks || []).concat();
    const { networkUuid } = action.meta.input;
    const network = networks.find((network) => network.networkUuid === networkUuid);
    if (!network) {
      return {};
    }
    network.planningHardFork = {
      startAt: new Date().toISOString(),
    };

    return {
      networks,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'retry_apply_hard_fork_success',
      },
    };
  });

export const updateNodeInstanceTypeReducer = NetworkActions.updateNodeInstanceType.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_set_node_server_type'),
        },
      };
    }
    const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
    const { status, data } = action.payload.updateNodeInstanceType;

    if (status.length === 1 && status[0] === 'success' && data) {
      let selected = state.networkSelected;
      const networks = (state.networks || []).concat();
      const index = networks.findIndex((n) => n.networkUuid === networkUuid);

      if (index >= 0) {
        const indexCluster = networks[index].clusters.findIndex(
          (c) => c.clusterUuid === clusterUuid,
        );

        if (indexCluster >= 0) {
          const nodes = networks[index].clusters[indexCluster].nodes.filter(
            (n) => n.nodeUuid === nodeUuid,
          );
          nodes.forEach((n) => {
            n.serverInfo.status = 'pending';
            n.nodeInfo.status = 'pending';
          });
          if (selected && selected.networkUuid === networkUuid && nodes.length > 0) {
            selected = networks[index];
          }
        }
      }

      return {
        networks: networks,
        networkSelected: selected,
        snackBarState: {
          open: true,
          type: 'success',
          message: 'change_instance_type_started',
        },
      };
    }

    return data || status.includes('needRegister') || status.includes('needCharge')
      ? {}
      : {
          snackBarState: {
            open: true,
            type: 'error',
            message: 'failed_to_expand_node_volume_size',
          },
        };
  },
);

export const updateExplorerInstanceTypeReducer =
  NetworkActions.updateBlockExplorerInstanceType.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_set_node_server_type'),
        },
      };
    }
    const { networkUuid, clusterUuid } = action.meta.input;
    const { status, data } = action.payload.updateBlockExplorerInstanceType;

    if (status.length === 1 && status[0] === 'success' && data) {
      let selected = state.networkSelected;
      const networks = (state.networks || []).concat();
      const index = networks.findIndex((n) => n.networkUuid === networkUuid);

      if (index >= 0) {
        const indexCluster = networks[index].clusters.findIndex(
          (c) => c.clusterUuid === clusterUuid,
        );

        if (indexCluster >= 0) {
          if (networks[index]?.clusters[indexCluster]?.explorer) {
            networks[index].clusters[indexCluster].explorer!.serverInfo.status = 'pending';
          }

          if (selected && selected.networkUuid === networkUuid) {
            selected = networks[index];
          }
        }
      }

      return {
        networks: networks,
        networkSelected: selected,
        snackBarState: {
          open: true,
          type: 'success',
          message: 'change_instance_type_started',
        },
      };
    }

    return data || status.includes('needRegister') || status.includes('needCharge')
      ? {}
      : {
          snackBarState: {
            open: true,
            type: 'error',
            message: 'failed_to_expand_node_volume_size',
          },
        };
  });

export const updateBlockGasLimitToNodeReducer = NetworkActions.updateBlockGasLimit.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_update_block_gas_limit'),
        },
      };
    }

    const networks = (state.networks || []).concat();
    const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
    const network = networks.find((network) => network.networkUuid === networkUuid);
    if (!network) {
      return {};
    }

    const cluster = network.clusters.find((cluster) => cluster.clusterUuid === clusterUuid);
    if (!cluster) {
      return {};
    }

    const node = cluster.nodes.find((node) => node.nodeUuid === nodeUuid);
    if (node) {
      node.nodeInfo.status = 'pending';
    }
    return {
      networks,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'update_gas_limit_start',
      },
    };
  },
);

export const sendProposalReducer = NetworkActions.sendProposal.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_create_proposal'),
      },
    };
  }
  const networks = (state.networks || []).concat();
  let networkSelected = state.networkSelected;
  const { networkUuid, accountUuid, includeNodeUuids, address, authorize } = action.meta.input;
  const network = networks.find((network) => network.networkUuid === networkUuid);
  if (!network) {
    return {};
  }
  network.clusters.forEach((cluster) => {
    if (cluster.accountUuid === accountUuid) {
      cluster.nodes.forEach((node) => {
        if (
          node.nodeInfo.signer &&
          (includeNodeUuids?.includes(node.nodeUuid) || !includeNodeUuids?.length)
        ) {
          node.nodeInfo.localProposals = (node.nodeInfo.localProposals || [])
            .filter((proposal) => proposal.address != address)
            .concat({ address, authorize });
        }
      });
    }
  });
  if (networkSelected && networkSelected.networkUuid === network.networkUuid) {
    networkSelected = { ...network };
  }
  return {
    networks,
    networkSelected,
    snackBarState: {
      open: true,
      type: 'success',
      message: 'create_proposal_success',
    },
  };
});

export const discardProposalReducer = NetworkActions.discardProposal.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: 'can_not_discard_proposal',
        },
      };
    }
    const { networkUuid, clusterUuid, nodeUuid, address } = action.meta.input;
    const networks = (state.networks || []).concat();
    let networkSelected = state.networkSelected;
    const network = networks.find((network) => network.networkUuid === networkUuid);
    if (!network) {
      return {};
    }
    const cluster = network.clusters.find((cluster) => cluster.clusterUuid === clusterUuid);
    if (!cluster) {
      return {};
    }
    const node = cluster.nodes.find((node) => node.nodeUuid === nodeUuid);
    if (node) {
      node.nodeInfo.localProposals = node.nodeInfo.localProposals?.filter(
        (val) => val.address != address,
      );
    }
    if (networkSelected && networkSelected.networkUuid === network.networkUuid) {
      networkSelected = { ...network };
    }

    return {
      networks,
      networkSelected,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'discard_proposal_success',
      },
    };
  },
);

export const listProposalHistoryReducer = NetworkActions.listProposalHistory.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_list_proposals'),
        },
      };
    }
    return {
      listProposalHistory: action.payload.listProposalHistory,
    };
  },
);

export const getGenesisJsonReducer = NetworkActions.getGenesisJson.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_get_genesis_json'),
        },
      };
    }
    return {};
  },
);

export const getGenesisJsonInExplorerReducer =
  NetworkActions.getGenesisJsonInExplorer.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_get_genesis_json'),
        },
      };
    }
    return {};
  });

export const updateExternalClefReducer = NetworkActions.updateExternalClef.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_update_external_clef'),
        },
      };
    }

    const networks = (state.networks || []).concat();
    const { networkUuid, clusterUuid, nodeUuid, host, port, username } = action.meta.input;
    const network = networks.find((network) => network.networkUuid === networkUuid);
    if (!network) {
      return {};
    }

    const cluster = network.clusters.find((cluster) => cluster.clusterUuid === clusterUuid);
    if (!cluster) {
      return {};
    }

    const node = cluster.nodes.find((node) => node.nodeUuid === nodeUuid);
    if (node?.signerInfo?.externalClef) {
      node.signerInfo.externalClef.host = host;
      node.signerInfo.externalClef.port = port;
      node.signerInfo.externalClef.username = username;
      node.signerInfo.externalClef.status = 'pending';
    }
    return {
      networks,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'update_external_clef_success',
      },
    };
  },
);

export const refreshConnectionToExternalClefReducer =
  NetworkActions.refreshExternalClefConnection.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_update_external_clef'),
        },
      };
    }

    const networks = (state.networks || []).concat();
    const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
    const network = networks.find((network) => network.networkUuid === networkUuid);
    if (!network) {
      return {};
    }

    const cluster = network.clusters.find((cluster) => cluster.clusterUuid === clusterUuid);
    if (!cluster) {
      return {};
    }

    const node = cluster.nodes.find((node) => node.nodeUuid === nodeUuid);
    if (node?.signerInfo?.externalClef) {
      node.signerInfo.externalClef.status = 'pending';
    }

    return {
      networks,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'refresh_connection_to_external_clef_success',
      },
    };
  });

export const updateBlockExplorerVersionReducer =
  NetworkActions.updateBlockExplorerVersion.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_update_explorer_version'),
        },
      };
    }
    if (!action.payload.updateBlockExplorerVersion) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: 'update_explorer_version_failed',
        },
      };
    }

    const networks = (state.networks || []).concat();
    const { networkUuid, clusterUuid } = action.meta.input;
    const network = networks.find((network) => network.networkUuid === networkUuid);
    if (!network) {
      return {};
    }

    const cluster = network.clusters.find((cluster) => cluster.clusterUuid === clusterUuid);
    if (!cluster) {
      return {};
    }

    if (cluster.explorer) {
      cluster.explorer.serverInfo.status = 'pending';
    }
    return {
      networks,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'update_explorer_version_started',
      },
    };
  });

export const setEndpointListenerReducer = NetworkActions.setEndpointListener.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_set_endpoint_listeners'),
        },
      };
    }
    return {};
  },
);

export const updateRuleOfInternalClefReducer =
  NetworkActions.updateRuleOfInternalClef.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_update_internal_clef_rules'),
        },
      };
    }

    const networks = (state.networks || []).concat();
    const { networkUuid, clusterUuid, nodeUuid, transferAddress } = action.meta.input;
    const rules = action.payload.updateRuleOfInternalClef;
    const network = networks.find((network) => network.networkUuid === networkUuid);
    if (!network) {
      return {};
    }

    const cluster = network.clusters.find((cluster) => cluster.clusterUuid === clusterUuid);
    if (!cluster) {
      return {};
    }

    const node = cluster.nodes.find((node) => node.nodeUuid === nodeUuid);
    if (node?.signerInfo?.internalClef) {
      node.signerInfo.internalClef.transferAddress = transferAddress;
      node.signerInfo.internalClef.rules = rules;
      node.signerInfo.internalClef.status = 'alive';
      node.nodeInfo.status = 'alive';
    }
    return {
      networks,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'update_transfer_address_done',
      },
    };
  });

export const migrateToInternalClefNodeReducer =
  NetworkActions.migrateToInternalClefNode.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(
            state,
            action.payload,
            'can_not_migrate_to_internal_clef_node',
          ),
        },
      };
    }

    const networks = (state.networks || []).concat();
    const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
    const network = networks.find((network) => network.networkUuid === networkUuid);
    if (!network) {
      return {};
    }

    const cluster = network.clusters.find((cluster) => cluster.clusterUuid === clusterUuid);
    if (!cluster) {
      return {};
    }

    const node = cluster.nodes.find((node) => node.nodeUuid === nodeUuid);
    if (node) {
      node.nodeInfo.status = 'pending';
      node.serverInfo.status = 'pending';
    }
    return {
      networks,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'start_to_migrate_to_internal_clef',
      },
    };
  });

export const listInsufficientStaticIpAddressLicensesReducer =
  PaymentAction.listInsufficientStaticIpAddressLicenses.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(
            state,
            action.payload,
            'can_not_list_insufficient_static_ip_licenses',
          ),
        },
      };
    }
    return {};
  });

export const migrateBootnodeReducer = NetworkActions.migrateBootnode.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_migrate_bootnode'),
        },
      };
    }
    const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
    const { status, data } = action.payload.migrateBootnode;

    if (status.length === 1 && status[0] === 'success' && data) {
      let selected = state.networkSelected;
      const networks = (state.networks || []).concat();
      const index = networks.findIndex((n) => n.networkUuid === networkUuid);

      if (index >= 0) {
        const indexCluster = networks[index].clusters.findIndex(
          (c) => c.clusterUuid === clusterUuid,
        );

        if (indexCluster >= 0) {
          const nodes = networks[index].clusters[indexCluster].nodes.filter(
            (n) => n.nodeUuid === nodeUuid,
          );
          nodes.forEach((n) => {
            n.serverInfo.status = 'pending';
            n.nodeInfo.status = 'pending';
          });
          if (selected && selected.networkUuid === networkUuid && nodes.length > 0) {
            selected = networks[index];
          }
        }
      }

      return {
        networks: networks,
        networkSelected: selected,
        snackBarState: {
          open: true,
          type: 'success',
          message: 'migrate_bootnode_started',
        },
      };
    }

    return data || status.includes('needRegister') || status.includes('needCharge')
      ? {}
      : {
          snackBarState: {
            open: true,
            type: 'error',
            message: 'failed_to_migrate_bootnode',
          },
        };
  },
);

export const associateStaticIpToInstanceReducer =
  NetworkActions.associateStaticIpAddress.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_associate_static_ip'),
        },
      };
    }
    const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
    const { status, data } = action.payload.associateStaticIpAddress;

    if (status.length === 1 && status[0] === 'success' && data) {
      let selected = state.networkSelected;
      const networks = (state.networks || []).concat();
      const index = networks.findIndex((n) => n.networkUuid === networkUuid);

      if (index >= 0) {
        const indexCluster = networks[index].clusters.findIndex(
          (c) => c.clusterUuid === clusterUuid,
        );

        if (indexCluster >= 0) {
          const nodes = networks[index].clusters[indexCluster].nodes.filter(
            (n) => n.nodeUuid === nodeUuid,
          );
          nodes.forEach((n) => {
            n.serverInfo.status = 'pending';
            n.nodeInfo.status = 'pending';
          });
          if (selected && selected.networkUuid === networkUuid && nodes.length > 0) {
            selected = networks[index];
          }
        }
      }

      return {
        networks: networks,
        networkSelected: selected,
        snackBarState: {
          open: true,
          type: 'success',
          message: 'associate_static_ip_started',
        },
      };
    }

    return data || status.includes('needRegister') || status.includes('needCharge')
      ? {}
      : {
          snackBarState: {
            open: true,
            type: 'error',
            message: 'failed_to_associate_static_ip',
          },
        };
  });

export const disassociateStaticIpFromInstanceReducer =
  NetworkActions.disassociateStaticIpAddress.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_disassociate_static_ip'),
        },
      };
    }
    const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
    let selected = state.networkSelected;
    const networks = (state.networks || []).concat();
    const index = networks.findIndex((n) => n.networkUuid === networkUuid);

    if (index >= 0) {
      const indexCluster = networks[index].clusters.findIndex((c) => c.clusterUuid === clusterUuid);

      if (indexCluster >= 0) {
        const nodes = networks[index].clusters[indexCluster].nodes.filter(
          (n) => n.nodeUuid === nodeUuid,
        );
        nodes.forEach((n) => {
          n.serverInfo.status = 'pending';
          n.nodeInfo.status = 'pending';
        });
        if (selected && selected.networkUuid === networkUuid && nodes.length > 0) {
          selected = networks[index];
        }
      }
    }

    return {
      networks: networks,
      networkSelected: selected,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'disassociate_static_ip_started',
      },
    };
  });

export const updateClusterTypeReducer = NetworkActions.updateClusterType.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_update_cluster'),
        },
      };
    }

    const { status, data } = action.payload.updateClusterType;

    if (status.length === 1 && status[0] === 'success' && data) {
      const networks = (state.networks || []).concat();
      const { networkUuid, clusterUuid, clusterType } = action.meta.input;
      const network = networks.find((network) => network.networkUuid === networkUuid);

      if (network) {
        network.clusters = network.clusters.map((cluster) => {
          if (cluster.clusterUuid === clusterUuid) {
            cluster.clusterType = clusterType;
          }
          return cluster;
        });
      }
      return {
        networks: networks,
        snackBarState: {
          open: true,
          type: 'success',
          message: 'cluster-updated-successfully',
        },
      };
    }

    return data || status.includes('needRegister') || status.includes('needCharge')
      ? {}
      : {
          snackBarState: {
            open: true,
            type: 'error',
            message: 'failed_to_update_cluster_type',
          },
        };
  },
);

export const reducer = createTypeReducer(
  initialState,
  closeSnackBarReducer,
  openSnackBarReducer,
  getAccountReducer,
  getProfileReducer,
  updateSelectNetworkReducer,
  acceptTermsOfServiceReducer,
  updateProfileReducer,
  listMembersReducer,
  addMemberReducer,
  updateMemberReducer,
  removeMemberReducer,
  changePrimaryOwnerReducer,
  updateAccountSelectedReducer,
  updateAccountReducer,
  listProvidersReducer,
  listNetworksReducer,
  getNetworkReducer,
  getNetworkRoleReducer,
  selectNetworkReducer,
  createNetworkReducer,
  updateNetworkReducer,
  destroyNetworkReducer,
  createClusterReducer,
  updateClusterReducer,
  deleteClusterReducer,
  createNodeReducer,
  updateNodeReducer,
  deleteNodeReducer,
  controlNodeReducer,
  controlBlockExplorerReducer,
  listNetworkAccessesReducer,
  grantNetworkRoleReducer,
  revokeNetworkRoleReducer,
  listAlertsReducer,
  listUnreadAlertsReducer,
  setAlertReadReducer,
  setAllAlertsReadReducer,
  sendInvitationReducer,
  listInvitationsReducer,
  cancelInvitationReducer,
  responseInvitationReducer,
  findClustersReducer,
  findNodesReducer,
  createAccountReducer,
  removeAccountReducer,
  listActiveLicensesReducer,
  listActiveLicensesSummaryReducer,
  listAvailableLicensesReducer,
  listInsufficientLicensesReducer,
  listInsufficientClusterLicensesReducer,
  listInsufficientNodeLicensesReducer,
  listInsufficientNodeServerLicensesReducer,
  listInsufficientVolumeLicensesReducer,
  estimateLicenseFeeReducer,
  listBillingsReducer,
  listCouponsReducer,
  listCardsReducer,
  registerCardReducer,
  purchaseLicensesReducer,
  cancelPurchaseLicenseReducer,
  listEndpointRestrictionsReducer,
  listExplorerRestrictionsReducer,
  listNodeRestrictionsReducer,
  setEndpointRestrictionReducer,
  setExplorerRestrictionReducer,
  setNodeRestrictionReducer,
  expandNodeVolumeReducer,
  expandBlockExplorerVolumeReducer,
  getNodeMetricsReducer,
  getNodeLogReducer,
  getExplorerMetricsReducer,
  getExplorerLogReducer,
  sendAllEtherFromNodeReducer,
  estimateTransferGasReducer,
  registerAddressReducer,
  updateContactReducer,
  checkClusterRemovalStatusReducer,
  checkNodeRemovalStatusReducer,
  getGeolocationReducer,
  getTxpoolStatusReducer,
  // listConsortiumsReducer,
  withdrawConsortiumReducer,
  updateConsortiumRoleReducer,
  updateNetworkOptionReducer,
  listCustomDomainsReducer,
  addCustomDomainReducer,
  removeCustomDomainReducer,
  checkCustomDomainStatusReducer,
  createBlockExplorerReducer,
  deleteBlockExplorerReducer,
  updateNodeVersionReducer,
  updateBlockExplorerReducer,
  updateNodeExplorerVersionReducer,
  scheduleHardForkReducer,
  voteHardForkScheduleReducer,
  listHardForkProposalReducer,
  updateExplorerToLatestHardForkReducer,
  updateLatestHardForkReducer,
  retryApplyHardForkToNetworkReducer,
  updateBlockGasLimitToNodeReducer,
  updateNodeInstanceTypeReducer,
  updateExplorerInstanceTypeReducer,
  sendProposalReducer,
  listProposalHistoryReducer,
  getGenesisJsonReducer,
  getGenesisJsonInExplorerReducer,
  discardProposalReducer,
  updateExternalClefReducer,
  updateBlockExplorerVersionReducer,
  setEndpointListenerReducer,
  updateRuleOfInternalClefReducer,
  migrateToInternalClefNodeReducer,
  refreshConnectionToExternalClefReducer,
  listInsufficientStaticIpAddressLicensesReducer,
  migrateBootnodeReducer,
  associateStaticIpToInstanceReducer,
  disassociateStaticIpFromInstanceReducer,
  updateClusterTypeReducer,
);
