import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
import * as PaymentActions from '~/stores/actions/payment-action';

// Component
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/submit-button';

import { INode, ICluster, INetwork } from '~/types/network-types';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Field, Form, FieldProps, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import { romanColor, dimGrayColor, whiteSmokeColor } from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

import CustomSelect from '~/components/common/custom-select';
import { clusterTypeEnterpriseSelection, clusterTypeSelection } from '~/types/network-selection';
import { VALIDATE_CLUSTER_TYPE_NODES } from '~/constants/validation';
import {
  BlockExplorerSizeType,
  ClusterSizeType,
  EstimateSummary,
  NodeSizeType,
} from '~/gapi/gtypes';
import RegisterAddressDialog from '~/components/common/register-address-dialog';
import ConfirmLicenseDialog from '~/components/common/confirm-license-dialog';

interface IProps extends WithStyles<typeof styles> {
  network: INetwork;
  cluster: ICluster;
  open: boolean;
  onClose: () => void;
}

type FormValues = {
  clusterType: string;
};

const ChangeClusterTypeDialog = (props: IProps) => {
  const { classes, open, network, cluster, onClose } = props;
  const [openConfirmLicenseDialog, setOpenConfirmLicenseDialog] = useState(false);
  const [openRegisterAddressDialog, setOpenRegisterAddressDialog] = useState(false);
  const [requireCard, setRequireCard] = useState(false);
  const [estimateDate, setEstimateDate] = useState('');
  const [formik, setFormik] = useState<FormikActions<FormValues>>();
  const [estimateFee, setEstimateFee] = useState<EstimateSummary>({
    estimate: [
      {
        totalPrice: 0,
        taxFee: 0,
        taxRate: 0,
        licenses: [],
        nextMonth: {
          totalPrice: 0,
          taxFee: 0,
          taxRate: 0,
          licenses: [],
        },
      },
    ],
    coupon: {
      usable: 0,
    },
  });

  const accountSeleted = useSelector((store: IStore) => store.appState.accountSeleted);
  const networkSelected = useSelector((store: IStore) => store.appState.networkSelected);
  const isNodeUpdating = useSelector((store: IStore) =>
    NetworkActions.updateNodeVersion.isPending(store),
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const validator = VALIDATE_CLUSTER_TYPE_NODES[cluster.clusterType || 'small_v2']
    ? VALIDATE_CLUSTER_TYPE_NODES[cluster.clusterType || 'small_v2'].allowed
    : [];

  const initialValues: FormValues = {
    clusterType: cluster.clusterType || 'small',
  };

  const select =
    accountSeleted && accountSeleted.plan === 'enterprise'
      ? clusterTypeEnterpriseSelection
      : clusterTypeSelection;

  const validateSchema = Yup.object().shape<FormValues>({
    clusterType: Yup.string()
      .required(t('required_field'))
      .oneOf(
        select.map((c) => c.value),
        t('invalid_cluster_type'),
      ),
  });

  const requireAddr = useMemo(() => {
    return !accountSeleted || !accountSeleted.paymentAddr || !accountSeleted.paymentAddr.country
      ? true
      : false;
  }, [accountSeleted]);

  const clusterTypeField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      const select =
        accountSeleted && accountSeleted.plan === 'enterprise'
          ? clusterTypeEnterpriseSelection
          : clusterTypeSelection;

      return (
        <>
          <div className={classes.formLabelLine}>
            <div className={classes.formLabel}>{t('cluster_type')}</div>
            {!!form.errors.clusterType && form.touched.clusterType && (
              <div className={classNames(classes.formLabel, classes.formError)}>
                {form.errors.clusterType}
              </div>
            )}
          </div>
          <div>
            <CustomSelect
              data-testid="cluster-type-select"
              {...field}
              id="member-cluster-add-cluster-type"
              valueSelected={field.value}
              placeholder={t('select_cluster_type')}
              items={select}
            />
          </div>
        </>
      );
    },
    [accountSeleted, classes.formError, classes.formLabel, classes.formLabelLine, t],
  );

  const onCloseRegisterAddressDialog = useCallback(
    (status: boolean = false) => {
      setOpenRegisterAddressDialog(false);
      setFormik(undefined);

      if (formik) {
        if (status) {
          // if registration successed, then start to buy licenses (auto submit)
          formik.submitForm();
        } else {
          formik.setSubmitting(false);
        }
      }
    },
    [formik],
  );

  const onOpenRegisterAddressDialog = useCallback(() => {
    setOpenRegisterAddressDialog(false);
  }, []);

  const onCloseConfirmLicenseDialog = (status: number = 0) => {
    setOpenConfirmLicenseDialog(false);
    setFormik(undefined);

    if (requireCard && status > 0) {
      setRequireCard(false);
    }

    if (formik) {
      if (status === 2) {
        // if purchase successed, then start creation (auto submit)
        formik.submitForm();

        if (accountSeleted) {
          dispatch(PaymentActions.listBillings({ accountUuid: accountSeleted.accountUuid }));
          dispatch(PaymentActions.listCoupons({ accountUuid: accountSeleted.accountUuid }));
          dispatch(
            PaymentActions.listActiveLicensesSummary({ accountUuid: accountSeleted.accountUuid }),
          );
        }
      } else {
        formik.setSubmitting(false);
      }
    }
  };

  const onOpenConfirmLicenseDialog = () => {
    setOpenConfirmLicenseDialog(true);
  };

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      const { setSubmitting } = formikActions;
      const { clusterType } = values;

      // Check selected account existence
      if (!accountSeleted) {
        return;
      }

      // check registration of address
      if (!accountSeleted.isRegisteredPaymentAddr) {
        setFormik(formikActions);
        onOpenRegisterAddressDialog();
        return;
      }

      try {
        const result = await dispatch(
          NetworkActions.updateClusterType({
            input: {
              accountUuid: accountSeleted.accountUuid,
              networkUuid: network.networkUuid,
              clusterUuid: cluster.clusterUuid,
              clusterType: clusterType as ClusterSizeType,
            },
          }),
        );
        const { status, execAt } = result.updateClusterType;
        if (status.includes('success')) {
          setSubmitting(false);
          onClose();
        } else if (status.includes('needCharge')) {
          // update state
          setRequireCard(status.includes('needRegister') ? true : false);
          setFormik(formikActions);

          const needs = await dispatch(
            PaymentActions.listInsufficientClusterTypeLicenses({
              accountUuid: accountSeleted.accountUuid,
              clusterType: clusterType as ClusterSizeType,
            }),
          );

          const params = {
            purchaseDate: execAt,
            licenses: needs.listInsufficientClusterTypeLicenses.map((l) => ({
              licenseItemId: l.licenseItemId,
              qty: l.qty,
            })),
          };
          const fee = await dispatch(
            PaymentActions.estimateLicenseFee({
              accountUuid: accountSeleted.accountUuid,
              input: [params],
            }),
          );
          setEstimateDate(execAt);

          if (fee.estimateLicenseFee.estimate.length > 0) {
            setEstimateFee(fee.estimateLicenseFee);
          }

          onOpenConfirmLicenseDialog();
        } else {
          setSubmitting(false);
          onClose(); // unknown status
        }
      } catch (error) {}
      setSubmitting(false);
    },
    [
      accountSeleted,
      onOpenRegisterAddressDialog,
      dispatch,
      network.networkUuid,
      cluster.clusterUuid,
      onClose,
    ],
  );

  return (
    <>
      <RegisterAddressDialog
        open={openRegisterAddressDialog}
        onClose={onCloseRegisterAddressDialog}
      ></RegisterAddressDialog>
      <ConfirmLicenseDialog
        open={openConfirmLicenseDialog}
        onClose={onCloseConfirmLicenseDialog}
        estimateDate={estimateDate}
        estimateFee={estimateFee}
        requireAddr={requireAddr}
        requireCard={requireCard}
      ></ConfirmLicenseDialog>
      <CustomDialog open={open} onClose={onClose}>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={onSubmit}
          render={({ isValid, isSubmitting, values }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-change-cluster-type-title">{t('change_cluster_type')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div className={classes.formSection}>
                  <Field name="clusterType" render={clusterTypeField} />
                </div>
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  data-testid="cancel-button"
                  id="member-change-cluster-type-cancel"
                  disabled={isSubmitting || isNodeUpdating}
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={onClose}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  data-testid="update-button"
                  id="member-change-cluster-type-submit"
                  isValid={isValid}
                  isSubmitting={isSubmitting || isNodeUpdating}
                  label={t('update')}
                  submittingLabel={t('updating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    </>
  );
};

const styles = createStyles({
  root: {},
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formControlLabel: {
    marginLeft: '-14px',
    '& .MuiCheckbox-root': {
      padding: '12px',
    },
  },
  formSection: {
    marginTop: 10,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

export default withStyles(styles)(ChangeClusterTypeDialog);
